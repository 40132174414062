import React from 'react'
import { GxInput } from '@garpix/garpix-web-components-react'
import style from './styles/index.module.scss'
import useTranslate from '../../hooks/useTranslate'

/**
 * @typedef IntlType
 * @type {object}
 * @property {string} id - an ID.
 * @property {string} defaultMessage - default message.
 */

/**
 * Инпут
 * @component
 *
 * @param {string|IntlType} label
 * @param {string|IntlType} placeholder
 * @param {string|IntlType} errorText - текст ошибки
 */
const Input = React.forwardRef(
  (
    {
      children,
      theme = 'input_default',
      placeholder = '',
      label = '',
      errorStyle = 'error_text',
      errorText = false,
      required,
      value = '',
      error = false,
      regexp = '',
      ...props
    },
    ref
  ) => {
    const handleChange = (e) => {
      e.target.value = e.target.value.replace(regexp, '')
      return props['onGx-input'] ? props['onGx-input'](e) : e
    }
    const { translate } = useTranslate()
    return (
      <>
        <GxInput
          className={style[theme]}
          placeholder={translate(placeholder)}
          label={translate(label)}
          required={required}
          error={error}
          value={value}
          {...props}
          ref={ref}
          onGx-input={handleChange}
        >
          {children}
          {error && errorText && (
            <div className={style[errorStyle]} slot='help-text'>
              {translate(errorText)}
            </div>
          )}
        </GxInput>
      </>
    )
  }
)
export default Input
