import React, { useMemo, useState } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { useStoreon } from 'storeon/react'
import MoveCargo from '../../../views/BaseModal/ModalContent/MoveCargo'
import { useCargo } from '../../../apps/appProject/hooks'

const cargoGroupLabel = defineMessage({
  id: 'cargoGroupNum',
  defaultMessage: 'Группа {num}'
})

const MoveToGroup = ({
  cargoIndex,
  groupIndex,
  handleClose,
  values,
  setValues
}) => {
  const [newGroupIndex, setNewGroupIndex] = useState(null)
  const { currentUser } = useStoreon('currentUser')
  const limitCargoCount = currentUser?.current_tariff?.cargos_count
  const [groups, setGroups] = useState(values.groups)
  const { createGroup } = useCargo()
  const intl = useIntl()

  const handleMove = () => {
    const copyGroups = [...groups]
    const cargo = copyGroups[groupIndex].cargoes.splice(cargoIndex, 1)[0]
    copyGroups[newGroupIndex].cargoes.push(cargo)
    setValues({ groups: copyGroups })
    handleClose()
  }
  const handleCreate = () => {
    const numGroup = groups.length + 1
    const newGroup = createGroup()
    newGroup.title = intl.formatMessage(cargoGroupLabel, { num: numGroup })
    const newGroups = [...groups, newGroup]
    setValues({ groups: newGroups })
    setGroups(newGroups)
  }
  const handleChange = e => setNewGroupIndex(Number(e.target.value))

  const isLimitCargo = useMemo(() => {
    const cargoCount = groups.reduce(
      (acc, el) => acc + el.cargoes.length,
      0
    )
    return cargoCount >= limitCargoCount && limitCargoCount !== -1
  }, [limitCargoCount, groups])

  return (
    <MoveCargo
      groupIndex={groupIndex}
      newGroupIndex={newGroupIndex}
      cargoGroups={groups}
      limit={isLimitCargo}
      handleChange={handleChange}
      handleMove={handleMove}
      handleCreate={handleCreate}
      handleClose={handleClose}
    />
  )
}

export default MoveToGroup
