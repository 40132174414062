import { Formik } from 'formik'
import React from 'react'
import Tables from '../../views/ListPageViews/Tables'

const Header = ({ headers, filterParams, loadData, handleCheckAll, checkID, allID, type, handleDeleteCargo, handleResetCheck }) => {
  const handleClickHeader = (
    typeFilter,
    setFieldValue,
    handleSubmit,
    values
  ) => {
    handleResetCheck()
    let filter = ''
    let value = ''
    filter = values.ordering || ''

    if (filter.includes(typeFilter)) {
      value = filter.includes('-') ? undefined : `-${typeFilter}`
    } else {
      value = typeFilter
    }

    setFieldValue('ordering', value)
    handleSubmit()
  }

  return (
    <Formik
      initialValues={filterParams}
      onSubmit={(values) => {
        loadData(1, values)
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Tables.Header
            type={type}
            handleClickHeader={handleClickHeader}
            handleSubmit={handleSubmit}
            values={values}
            setFieldValue={setFieldValue}
            headers={headers}
            handleCheckAll={handleCheckAll}
            handleDeleteCargo={handleDeleteCargo}
            checkID={checkID}
            allID={allID}
          />
        )
      }}
    </Formik>
  )
}

export default Header
