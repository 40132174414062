import { createIntl, createIntlCache } from 'react-intl'
import Russian from '../../../public/messages/ru'
import English from '../../../public/messages/en'
import api from '../../api'
import { LOCALE } from '../../const'

const messages = {
  [LOCALE.RU]: Russian,
  [LOCALE.EN]: English
}

const cache = createIntlCache()
const locale = api.getLanguage()
const intl = createIntl({
  locale,
  messages: messages[locale]
}, cache)

export default intl
