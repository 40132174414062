import React, { useCallback } from 'react'
import api from '@/api/services/project'
import { fetchergetTemplatesExel } from '@/apps/Sidebars/utils'
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll'
import { useModalStore } from '@/hooks/Modals'
import useTranslate from '@/hooks/useTranslate'
import { MODAL_THEMES } from '@/views/BaseModal/Base'
import { Item } from './views'
import { KEYS_FETCHER } from '@/const'
import styles from './styles/index.module.scss'

const TemplatesProjects = () => {
  const { intl } = useTranslate()
  const { openModal, closeModal } = useModalStore()

  const { data, isLoadingMore, isReachingEnd, setSize, mutate } = useInfiniteScroll(
    KEYS_FETCHER.get_xlsx_template,
    fetchergetTemplatesExel
  )

  const handleScrollInternal = useCallback(
    (event) => {
      const { scrollTop, clientHeight, scrollHeight } = event.target
      if (
        scrollHeight - scrollTop <= clientHeight * 2 &&
        !isLoadingMore &&
        !isReachingEnd
      ) {
        setSize((size) => size + 1)
      }
    },
    [isLoadingMore, isReachingEnd, setSize]
  )

  const handleClickEdit = ({ id }) => {
    openModal('uploadFileProjectGazprom', {
      theme: MODAL_THEMES.large_700,
      isEdit: true,
      id,
      mutateProp: mutate
    })
  }

  const handleClickDelete = ({ id, title = '' }) => {
    openModal('remove', {
      type: `шаблон "${title.toLowerCase()}" `,
      handleAccept: () => {
        api
          .deleteTemplateXllsx(id)
          //   .then((res) => {})
          //   .catch((err) => {})
          .finally(() => {
            mutate()
          })
        closeModal()
      },
      theme: MODAL_THEMES.DEFAULT_zINDEX_HIGHT
    })
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.wrapper__title}>Сохраненные шаблоны</h1>
      <div className={styles.wrapper__list} onScroll={handleScrollInternal}>
        {data.length === 0
          ? (
            <div className={styles.wrapper__empty}>
              {intl.formatMessage({
                id: 'dont_find_templates',
                defaultMessage: 'У вас пока нет сохраненных шаблонов'
              })}
            </div>
            )
          : (
              data.map((el) => {
                return (
                  <Item
                    key={el.id}
                    handleClickDelete={handleClickDelete}
                    handleClickEdit={handleClickEdit}
                    {...el}
                  />
                )
              })
            )}
      </div>
    </div>
  )
}

export default TemplatesProjects
