import { useStoreon } from 'storeon/react'
import SocketBase from '../../../api/socketApi/SocketBase'

const useSocket = () => {
  const { instanceSocketCore, dispatch } = useStoreon('instanceSocketCore')

  const update = (socketInstance) => dispatch('instanceSocketCore/update', socketInstance)
  const init = (url, qp) => {
    const initSocket = new SocketBase()
    initSocket.init(url, qp)
    return initSocket
  }
  return {
    socket: instanceSocketCore,
    update,
    init
  }
}

export default useSocket
