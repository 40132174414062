import { useEffect, useRef } from 'react'
import { useStoreon } from 'storeon/react'
import { ANIMATION_TIME } from '@/const'
import { isUndefined } from '@/utils'

/**
 * * хук для управления правым сайдбаром
 * @hook useSidebar
 */
export const useSidebar = () => {
  const animationRef = useRef()
  const { sidebar, dispatch } = useStoreon('sidebar')

  const {
    isOpen,
    type,
    sidebarProps,
    currentShowingBlock,
    isStartClosingAnimation,
    isCollapse
  } = sidebar

  /**
   * @param {*} e - event
   * * метод для закрытия сайдбара по тапу на esc
   */
  const handleCloseEsc = (e) => {
    if (e.key === 'Escape') {
      handleClose()
    }
  }

  /**
   * @function handleOpen
   * * метод для открытия модалки
   */
  const handleOpen = ({ type, sidebarProps }) => {
    dispatch('sidebar/open', { type, sidebarProps })
    document.addEventListener('keyup', handleCloseEsc)
  }

  /**
   * @function handleCollapse
   * * метод для колапса модалки
   */
  const handleCollapse = () => {
    dispatch('sidebar/collapse')
  }

  /**
   * @function handleExpand
   * * метод для експанда модалки
   */
  const handleExpand = () => {
    dispatch('sidebar/expand')
  }

  /**
   * @function handleClose
   * * метод для плавного закрытия модалки
   */
  const handleClose = () => {
    if (!isStartClosingAnimation) {
      dispatch('sidebar/setAnimationClosing', { isClosing: true })
      animationRef.current = setTimeout(() => {
        dispatch('sidebar/close')
        clearTimeout(animationRef.current)
        document.removeEventListener('keyup', handleCloseEsc)
      }, ANIMATION_TIME.ACCORDION)
    }
  }

  /**
   * @function handleCloseInstantly
   * * метод для мгновенного закрытия модалки
   */
  const handleCloseInstantly = () => {
    dispatch('sidebar/close')
  }

  useEffect(() => {
    return () => {
      if (!isUndefined(animationRef.current)) {
        clearTimeout(animationRef.current)
      }
    }
  }, [])

  return {
    handleOpen,
    handleClose,
    handleCollapse,
    handleExpand,
    handleCloseInstantly,
    isOpen,
    type,
    sidebarProps,
    currentShowingBlock,
    isStartClosingAnimation,
    isCollapse
  }
}
