import React from 'react'
import Sidebar from './components/sidebar'
import { NoSSR } from '@/components/NoSSR'
import { TemplatesProjects } from '@/apps/Sidebars/components/content'
import { useSidebar } from '@/hooks/useSidebar'
import { SIDEBAR_TYPES } from '@/const'

/**
 * набор сайдбаров
 * key- тип сайдбара
 * value - компонента контеента сайдбара
 */
const sidebars = {
  [SIDEBAR_TYPES.TEMPLATES_PROJECTS]: TemplatesProjects,
  empty: () => null
}

const Sidebars = () => {
  const { handleClose, isOpen, type, sidebarProps, isStartClosingAnimation } =
    useSidebar()

  const CurrentSidebar = sidebars[type ?? 'empty']
  //   const afterHideModal = () => closeModal()

  //   const onOverlayDismiss = (e) => {
  //     if (isUnclosable) {
  //       e.preventDefault()
  //     }
  //   }

  //   const handleCloseBaseModal = !isUnclosable ? closeModal : undefined
  return (
    <NoSSR>
      <Sidebar
        isOpen={isOpen}
        isStartClosingAnimation={isStartClosingAnimation}
        handleClose={handleClose}
      >
        <CurrentSidebar {...sidebarProps} />
      </Sidebar>
      {/* <BaseModal
        handleClose={handleCloseBaseModal}
        open={isOpen || undefined}
        onGx-after-hide={afterHideModal}
        onGx-overlay-dismiss={onOverlayDismiss}
        dataAttr={showModal}
        theme={modalProps.theme}
        colorTheme={modalProps?.colorTheme}
      >
        <CurrentModal
          {...modalProps}
          setUnclosable={setUnclosable}
          isUnclosable={isUnclosable}
          handleClose={closeModal}
        />
      </BaseModal> */}
    </NoSSR>
  )
}

export default Sidebars
