import React, { useRef, useState } from 'react'
import { useModalIntlContent, useModalStore } from '../hooks/Modals'
import { convertError, uuidv4 } from '../utils'
import useLimit from '../hooks/useLimit'
import Button from '../views/Button'
import Icon from '../views/Icon'
import { MODAL_THEMES } from '@/views/BaseModal/Base'
import { fileIcon } from '../images'
import { BUTTON_THEME, ENTITY_TYPES } from '../const'
import { useIntl } from 'react-intl'
import {
  cargoApiService,
  cargoSpaceApiService,
  projectApiService
} from '../api/services'
import usePage from '../hooks/usePage'

const loadSchemes = {
  project: projectApiService.createProjectFromFile,
  project_samle_xlsx: projectApiService.createProjectFromFileSamleXlsx,
  cargo_space: cargoSpaceApiService.createFileSpace,
  cargo: cargoApiService.createCargosFromFile
}

const customSchemes = {
  type1: projectApiService.createProjectFromFiles,
  metal_profile: projectApiService.createProjectFromFiles
}

const Load = ({ type, handleModalSocket }) => {
  const { page } = usePage()
  const company = page.init_state.global.current_user.company
  const isTemplatesFile =
    page.init_state.global.current_user.current_tariff.is_parsing_templates
  const customUploadType = company?.options?.custom_upload_excel
  const { openModal, closeModal, contentTypes } = useModalStore()
  const tariffContentModal = useModalIntlContent(contentTypes.tariffLimit)
  const warningError = useModalIntlContent(contentTypes.warning)
  const intl = useIntl()

  const [GLSInputKey, setGLSInputKey] = useState(uuidv4)
  const GLSInputRef = useRef(null)

  const [SamleXlsxKey, setSamleXlsxKey] = useState(uuidv4)
  const SamleXlsxRef = useRef(null)

  const companyInputRef = useRef(null)
  const [companyInputKey, setCompanyInputKey] = useState(uuidv4)
  const { isFreeTariffLimit } = useLimit()
  const isCustomExcel = customUploadType && type === ENTITY_TYPES.PROJECT

  const handleClickLoadBtn = () => {
    if (isFreeTariffLimit) {
      openModal('tariffLimit', tariffContentModal)
      return
    }
    if (isCustomExcel) {
      openModal('uploadFile', {
        handleGLSFormatLoad: () => GLSInputRef.current.click(),
        handleCompanyFormatLoad: () => companyInputRef.current.click()
      })
      return
    }
    if (type === ENTITY_TYPES.PROJECT && isTemplatesFile) {
      openModal('uploadFileProjectGazprom', {
        theme: MODAL_THEMES.large_700,
        handleSamleXlsxLoadRef: SamleXlsxRef.current
      })
      return
    }
    GLSInputRef.current.click()
  }

  const handleGLSLoad = async (e) => {
    const files = e.target.files
    const sendFile = loadSchemes[type]
    if (files && files[0] && sendFile) {
      try {
        await sendFile({ file: files[0] })
        handleModalSocket()
      } catch (e) {
        const error = convertError(e)
        openModal('commonConfirm', { ...warningError, description: error })
      }
      setGLSInputKey(uuidv4)
    }
  }

  const handleSamleXlsxLoad = async (e) => {
    const files = e.target.files
    const fieldsData = e.target.data
    const sendFile = loadSchemes.project_samle_xlsx
    if (files && files[0] && sendFile) {
      try {
        await sendFile({ file: files[0], ...fieldsData })
        handleModalSocket()
      } catch (e) {
        const error = convertError(e)
        openModal('commonConfirm', { ...warningError, description: error })
      }
      setSamleXlsxKey(uuidv4)
    }
  }

  const handleCompanyLoad = async (e) => {
    const files = e.target.files
    const sendFile = customSchemes[customUploadType]
    if (files && sendFile) {
      try {
        await sendFile({ files })
        handleModalSocket()
      } catch (e) {
        const error = convertError(e)
        openModal('commonConfirm', { ...warningError, description: error })
      } finally {
        setCompanyInputKey(uuidv4)
        closeModal()
      }
    }
  }

  const label = intl.formatMessage({
    id: 'uploadFile',
    defaultMessage: 'Загрузить файл'
  })

  return (
    <>
      <Button theme={BUTTON_THEME.STROKE_ICON.SMALL} onClick={handleClickLoadBtn}>
        <Icon src={fileIcon} alt='download' slot='icon-left' />
        {label}
      </Button>
      <input
        name='file_GLS'
        type='file'
        ref={GLSInputRef}
        key={GLSInputKey}
        onChange={handleGLSLoad}
        accept='.xlsx, .xls'
        style={{ display: 'none' }}
      />
      <input
        name='file_samle_xlsx'
        type='file'
        ref={SamleXlsxRef}
        key={SamleXlsxKey}
        onChange={handleSamleXlsxLoad}
        accept='.xlsx, .xls'
        style={{ display: 'none' }}
      />
      <input
        name='file_company'
        type='file'
        ref={companyInputRef}
        key={companyInputKey}
        onChange={handleCompanyLoad}
        accept='.xlsx, .xls'
        multiple
        style={{ display: 'none' }}
      />
    </>
  )
}

export default Load
