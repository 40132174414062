import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import Load from '@/components/Load'
import AddComponent from '@/components/AddComponent'
import {
  cargoSpaceApiService,
  projectApiService,
  cargoApiService
} from '@/api/services'
import OpenTemplateList from '@/views/ProjectsViews/OpenTemplateList'
import Download from '../Download'
import { ENTITY_TYPES } from '@/const'
import style from './styles/index.module.scss'
import usePage from '@/hooks/usePage'

const paramsType = {
  project: {
    text: {
      id: 'createProject',
      defaultMessage: 'Создать проект'
    },
    upload: projectApiService.loadProjectExample
  },
  cargo_space: {
    text: {
      id: 'newSpace',
      defaultMessage: 'Новое пространство'
    },
    upload: cargoSpaceApiService.loadCargoSpaceExample
  },
  cargo: {
    text: {
      id: 'addCargo',
      defaultMessage: 'Добавить груз'
    },
    upload: cargoApiService.loadCargoExample
  }
}

const Buttons = ({ type, handleModalSocket }) => {
  const intl = useIntl()
  const { page } = usePage()
  const isParsingTemplates =
    page.init_state.global.current_user.current_tariff.is_parsing_templates

  const templateBtn = (() => {
    if (type === ENTITY_TYPES.PROJECT && isParsingTemplates) {
      return (
        <div className={style.button_wrapper}>
          <OpenTemplateList />
        </div>
      )
    }
    return null
  })()

  return (
    <>
      <div className={style.button_wrapper}>
        <Download handleUpload={paramsType[type].upload} />
      </div>
      <div className={style.button_wrapper}>
        <Load type={type} handleModalSocket={handleModalSocket} />
      </div>
      {templateBtn}
      <div className={classNames(style.button_wrapper, style.button_fixed)}>
        <AddComponent
          addButtonText={intl.formatMessage(paramsType[type].text)}
          type={type}
          style={style.button_add}
        />
      </div>
    </>
  )
}

export default Buttons
