import React, { useRef, useCallback, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import AsyncSelect from 'react-select/async'
import { useInfiniteScroll } from '@/hooks/useInfiniteScroll'
import style from './styles/index.module.scss'

const AutoCompleteSelect = ({
  label = '',
  keyFetcher = '',
  fetchUrl,
  handleChange,
  handleChangeInputProxy,
  name,
  inputValue: inputValueText,
  error,
  errorText,
  ...props
}) => {
  const id = v4()
  const selectRef = useRef()
  const menuRef = useRef()
  const [inputValue, setInputValue] = useState(inputValueText)
  const [selectedOption, setSelectedOption] = useState(null)
  const { data, isLoadingMore, isReachingEnd, setSize, mutate } = useInfiniteScroll(
    keyFetcher,
    (data) => fetchUrl({ search: inputValue, ...data })
  )

  const colourStyles = {
    control: (styles, data) => {
      const errorStyle = error ? { borderColor: 'var(--gx-color-red)' } : {}
      return { ...styles, backgroundColor: 'white', ...errorStyle }
    },
    input: (styles) => ({
      ...styles,
      width: '100%',
      '*': {
        width: '100%'
      }
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none'
    }),
    option: (styles) => {
      return {
        ...styles
      }
    },
    menu: (styles) => ({
      ...styles,
      position: 'absolute'
    }),
    multiValue: (styles) => {
      return {
        ...styles
      }
    }
  }

  const onChange = (selectedOption) => {
    if (selectedOption) {
      const { value } = selectedOption
      setSelectedOption(selectedOption)
      if (value) {
        const template = data.find((el) => el.id === value)
        handleChange(template)
        setInputValue(template.title)
      }
    } else {
      handleChange(false)
      setSelectedOption(null)
      setInputValue('')
    }
  }

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-change' || action === 'set-value') {
      setInputValue(newValue)
      handleChangeInputProxy(newValue)
      mutate()
    }
  }

  const handleScrollInternal = useCallback(
    (event) => {
      const { scrollTop, clientHeight, scrollHeight } = event.target
      if (
        scrollHeight - scrollTop <= clientHeight * 2 &&
        !isLoadingMore &&
        !isReachingEnd
      ) {
        setSize((size) => size + 1)
      }
    },
    [isLoadingMore, isReachingEnd, setSize]
  )

  const customComponents = {
    MenuList: (props) => (
      <div
        className={style.menu__list}
        onScroll={handleScrollInternal}
        ref={menuRef}
      >
        {props.children}
      </div>
    )
  }

  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.scrollTo(0, menuRef.current.scrollHeight / 2) // сохранение позиции прокрутки
    }
  }, [data])

  const templates = data?.length
    ? data.reduce((acc, v) => {
        acc.push({
          label: v.title ? v.title : '-',
          value: v.id
        })
        return acc
      }, [])
    : []

  const loadOptions = (inputValue, callback) => {
    const filteredOptions = templates.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    )
    callback(filteredOptions)
  }

  return (
    <div className={style.wrapper}>
      <label htmlFor={id} className={style.label}>
        {label}
      </label>
      <AsyncSelect
        ref={selectRef}
        id={id}
        name={name}
        cacheOptions
        isClearable
        indicatorSeparator={false}
        loadOptions={loadOptions}
        defaultOptions={templates}
        styles={colourStyles}
        placeholder='Выберите'
        noOptionsMessage={() => 'Нет доступных вариантов'}
        loadingMessage={() => 'Загрузка...'}
        components={customComponents}
        onChange={onChange}
        inputValue={inputValueText}
        onInputChange={handleInputChange}
        value={selectedOption}
        {...props}
      />
      {error && errorText && (
        <div className={style.error} slot='help-text'>
          {errorText}
        </div>
      )}
    </div>
  )
}

export default React.memo(AutoCompleteSelect)
