import useSWRInfinite from 'swr/infinite'
import { PAGE_SIZE } from '@/const'

export const useInfiniteScroll = (key, fetcher, config = {}) => {
  const { pageSize = PAGE_SIZE, ...restConfig } = config
  const getKey = (pageIndex, previousPageData) => {
    if (previousPageData && !previousPageData.results.length) return null // Нет данных
    return {
      key,
      page: pageIndex + 1,
      page_size: PAGE_SIZE
    }
  }
  const swr = useSWRInfinite(getKey, fetcher, {
    ...restConfig
  })
  const { data, size, setSize, isValidating, isLoading, mutate, error } = swr
  const results = data ? data.map((res) => res.results).flat() : []
  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = results.length === 0 && !isLoadingMore
  const isReachingEnd = isEmpty || (data && data[data.length - 1]?.next === null)
  const isRefreshing = isValidating && data && data.length === size
  const handleNext = () => setSize(size + 1)

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget
    if (!isLoadingMore && scrollHeight - scrollTop <= clientHeight * 2) {
      !isReachingEnd && handleNext()
    }
  }

  const handleReset = () => setSize(1)

  return {
    data: results,
    isLoadingMore,
    isReachingEnd,
    isRefreshing,
    isLoading,
    error,
    isEmpty,
    handleNext,
    handleScroll,
    handleReset,
    pageSize,
    mutate,
    setSize
  }
}
