import React from 'react'

import { GxIcon } from '@garpix/garpix-web-components-react'

import Spinner from '@/views/Spinner'
import Button from '@/views/Button'

import useTranslate from '@/hooks/useTranslate'

import { clockWiseIcon, reportFilesIcon } from '@/images'
import { BUTTON_THEME, SPINNER_VARIANT } from '@/const'

import styles from './styles/index.module.scss'

const LoaderOrIconView = ({ isLoading, icon }) =>
  isLoading
    ? (
      <Spinner
        variant={SPINNER_VARIANT.FOR_BTN}
        className={styles.wrap__btn__spinner}
        slot='icon-left'
      />
      )
    : (
      <GxIcon src={icon} slot='icon-left' />
      )

/**
 * @view
 * @param {obj} downloadAllReportsProps
 * @param {obj} downloadLastReportProps
 */
const DownloadReportView = ({
  downloadAllReportsProps,
  downloadLastReportProps
}) => {
  const { translate } = useTranslate()

  return (
    <div className={styles.wrap}>
      <Button
        onClick={downloadLastReportProps.handleClick}
        disabled={downloadLastReportProps.isLoading}
        theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
        className={styles.wrap__btn}
      >
        <LoaderOrIconView
          icon={clockWiseIcon}
          isLoading={downloadLastReportProps.isLoading}
        />
        {translate({
          id: 'latestReport',
          defaultMessage: 'Последние отчеты'
        })}
      </Button>
      <Button
        onClick={downloadAllReportsProps.handleClick}
        disabled={downloadAllReportsProps.isLoading}
        theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
        className={styles.wrap__btn}
      >
        <LoaderOrIconView
          icon={reportFilesIcon}
          isLoading={downloadAllReportsProps.isLoading}
        />
        {translate({
          id: 'allReports',
          defaultMessage: 'Все отчеты'
        })}
      </Button>
    </div>
  )
}

export default DownloadReportView
