import React from 'react'
import { GxForm } from '@garpix/garpix-web-components-react'
import { useStoreon } from 'storeon/react'

import ModalView from '../../../ModalView'
import Input from '../../../Input'
import PasswordRule from '../../../PasswordRules'

import useTranslate from '../../../../hooks/useTranslate'

import { BUTTON_THEME } from '../../../../const'
import { PASSWORD_VAL_FUNC_ARR } from '../../../../apps/appRegistration/consts'

import styles from '../styles/index.module.scss'

// const inputs = [
//   {
//     id: 0,
//     name: 'old_password',
//     label: {
//       id: 'modals.changePassword.oldPassword',
//       defaultMessage: 'Старый пароль'
//     }
//   },
//   {
//     id: 1,
//     name: 'new_password',
//     label: {
//       id: 'modals.changePassword.newPassword',
//       defaultMessage: 'Новый пароль'
//     }
//   },
//   {
//     id: 2,
//     name: 'password2',
//     label: {
//       id: 'modals.changePassword.password2',
//       defaultMessage: 'Повторите новый пароль'
//     }
//   }
// ]

const ChangePassword = ({ formik, handleClose, isUnclosable }) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    isValid,
    dirty,
    isSubmitting
  } = formik
  const { currentUser } = useStoreon('currentUser')
  const { translate, intl } = useTranslate()
  const showPasswordError = !PASSWORD_VAL_FUNC_ARR.includes(errors?.new_password)
  console.log('values', values)
  return (
    <GxForm onGx-submit={handleSubmit} data-cy='change-password-form'>
      <ModalView.Title>
        {intl.formatMessage({
          id: 'changePassword',
          defaultMessage: 'Изменить пароль'
        })}
      </ModalView.Title>
      {currentUser?.is_needs_password_update && (
        <p className={styles.change_password__desc}>
          {translate({
            id: 'auth.message',
            defaultMessage:
              'Вам необходимо изменить пароль, чтобы активировать учетную запись'
          })}
        </p>
      )}

      {/* {inputs.map(({ label, name, id }) => {
        return (
          <div key={id} className={styles.change_password__input}>
            <Input
              label={intl.formatMessage(label)}
              name={name}
              type='password'
              onChange={handleChange}
              onGx-blur={handleBlur}
              error={touched[name] && errors[name]}
              errorText={errMessage}
              required
            />
          </div>
        )
      })} */}

      <div className={styles.change_password__input}>
        <Input
          label={intl.formatMessage({
            id: 'modals.changePassword.old_password',
            defaultMessage: 'Старый пароль'
          })}
          value={values.old_password}
          name='old_password'
          type='password'
          onGx-input={handleChange}
          onGx-blur={handleBlur}
          error={touched.old_password && errors.old_password}
          errorText={translate(errors.old_password)}
          required
        />
      </div>

      <div className={styles.change_password__input}>
        <Input
          label={intl.formatMessage({
            id: 'modals.changePassword.newPassword',
            defaultMessage: 'Новый пароль'
          })}
          value={values.new_password}
          name='new_password'
          type='password'
          onGx-input={handleChange}
          onGx-blur={handleBlur}
          error={touched.new_password && errors.new_password}
          errorText={showPasswordError && translate(errors.new_password)}
          required
        />
      </div>

      <div className={styles.change_password__input}>
        <Input
          label={intl.formatMessage({
            id: 'modals.changePassword.password2',
            defaultMessage: 'Повторите новый пароль'
          })}
          value={values.password2}
          name='password2'
          type='password'
          onGx-input={handleChange}
          onGx-blur={handleBlur}
          error={touched.password2 && errors.password2}
          errorText={showPasswordError && translate(errors.password2)}
          required
        />
      </div>

      <div className={styles.change_password__input}>
        <PasswordRule error={errors.new_password} />
      </div>

      <ModalView.Buttons>
        {!isUnclosable && (
          <ModalView.Buttons.Button
            data-cy='modal-cancel-btn'
            theme={BUTTON_THEME.STROKE_ICON.SMALL}
            onClick={handleClose}
          >
            {intl.formatMessage({
              id: 'cancel',
              defaultMessage: 'Отменить'
            })}
          </ModalView.Buttons.Button>
        )}
        <ModalView.Buttons.Button
          data-cy='modal-accept-btn'
          type='submit'
          theme={BUTTON_THEME.FILL_ICON.SMALL}
          disabled={isSubmitting || !(isValid && dirty)}
        >
          {intl.formatMessage({
            id: 'change',
            defaultMessage: 'Изменить'
          })}
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </GxForm>
  )
}

export default React.memo(ChangePassword)
