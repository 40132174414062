import React, { useEffect, useMemo, useRef } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { closeSmallIcon } from '@/images'
import { ANIMATION_TIME } from '@/const'
import styles from '@/apps/Sidebars/styles/index.module.scss'

const Sidebar = ({
  isOpen = false,
  width = 600,
  position = 'right',
  children = null,
  handleClose,
  isStartClosingAnimation
}) => {
  const sidebarRef = useRef()
  let timer

  useMemo(() => {
    if (sidebarRef.current) {
      if (!isOpen) {
        timer = setTimeout(() => {
          if (isStartClosingAnimation) {
            sidebarRef.current.style.setProperty('width', '0px')
            sidebarRef.current.style.setProperty('padding', '0px')
            sidebarRef.current.style.setProperty('visibility', 'hidden')
          }
          clearTimeout(timer)
        }, ANIMATION_TIME.ACCORDION + 1)
      } else {
        sidebarRef.current.style.setProperty('width', `${width}px`)
        sidebarRef.current.style.setProperty('padding', '30px')
        sidebarRef.current.style.setProperty('visibility', 'visible')
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.style.setProperty('width', '0px')
      sidebarRef.current.style.setProperty('padding', '0px')
      sidebarRef.current.style.setProperty('visibility', 'hidden')
    }
  }, [])

  useEffect(() => {
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const stylesWrapper = !isOpen
    ? {
        transform: `translateX(${width}px)`
      }
    : { transform: 'translateX(0)' }

  // if (!isOpen) {
  //   return null
  // }

  return (
    <>
      {createPortal(
        <>
          {isOpen
            ? (
              <div className={styles['sidebar-overlay']} onClick={handleClose} />
              )
            : null}
          <div
            ref={sidebarRef}
            style={{
              width: `${width}px`,
              padding: '30px',
              ...stylesWrapper
            }}
            className={classNames(styles.sidebar, styles[`sidebar--${position}`], {
              [styles['sidebar--open']]: isOpen
            })}
          >
            <div className={styles.close} onClick={handleClose} role='button'>
              <GxIcon src={closeSmallIcon} className={styles.close__icon} />
            </div>
            <div className={styles['sidebar-content']}>{children}</div>
          </div>
        </>,
        document.body
      )}
    </>
  )
}

export default Sidebar
