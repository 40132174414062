import React from 'react'
import { GxSelect } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import style from './styles/index.module.scss'

const Select = ({
  children,
  theme = 'default',
  label,
  errorText = 'Текст ошибки',
  required,
  error,
  className,
  value,
  ...props
}) => {
  return (
    <>
      <GxSelect
        className={classNames(style[`select_${theme}`], className)}
        label={label}
        required={required}
        error={error}
        value={value}
        {...props}
      >
        {children}
        <div className={style.error_text} slot='help-text'>
          {errorText}
        </div>
      </GxSelect>
    </>
  )
}

export default Select
