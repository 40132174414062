import dayjs from 'dayjs'
import { cloneDeep } from '../../utils'
import { DefaultProject, SOCKET_EVENTS } from '../../const'
import {INITIAL_MP_FIELDS} from "../../apps/appProject/const";

export const componentSerializers = (results) => {
  const res = {}
  results.map((item) => {
    const { position } = item
    if (!res[position]) {
      res[position] = []
    }
    res[position].push(item)
  })
  return res
}
export const dateSerializers = (start, finish) => {
  const currentDate = dayjs()
  const progressMaxValue = dayjs(finish).diff(dayjs(start), 'h')
  const progressValue = dayjs(currentDate).diff(dayjs(start), 'h')
  const restHour = progressMaxValue - progressValue
  const restDay = Math.ceil(restHour / 24)
  const lengthDay = Math.ceil(progressMaxValue / 24)
  return { progressValue, progressMaxValue, restDay, restHour, lengthDay }
}
export const serializedTariff = (currentTariff) => {
  if (!currentTariff) return null
  const { start_at: startAt, finish_at: finishAt } = currentTariff
  const { progressValue, progressMaxValue, restDay, restHour } = dateSerializers(
    startAt,
    finishAt
  )
  return {
    ...currentTariff,
    progressValue,
    progressMaxValue,
    restDay,
    restHour,
  }
}
export const slugSerializers = (data) => {
  return {
    pageNewsList: data.page_news_list || '',
    pageCargoSpacesList: data.page_cargo_spaces_list || '/',
    pageCargosList: data.page_cargos_list || '/',
    pageContractOffer: data.page_contract_offer || '/',
    pageCreateCargo: data.page_create_cargo || '/',
    pageCreateCargoSpace: data.page_create_cargo_space || '/',
    pageHelp: data.page_help || '/',
    pageLogin: data.page_login || '/',
    pagePasswordRecovery: data.page_password_recovery || '/',
    pagePrivacyPolicy: data.page_privacy_policy || '/',
    pageProfile: data.page_profile || '/',
    pageProjectReport: data.page_project_report || '/',
    pageProjectsList: data.page_projects_list || '/',
    pageRegistration: data.page_registration || '/',
    pageReportHistory: data.page_report_history || '/',
    pageTariffs: data.page_tariffs || '/',
    pageUpdateCargo: data.page_update_cargo || '/',
    pageUpdateCargoSpace: data.page_update_cargo_space || '/',
    pageNotifications: data.page_notifications_list || '/',
    pageHome: data.page_landing || '/',
    pageApiDocumentation: data.page_api_documentation || '/',
    pagefaq: data.page_faq || '/',
    youtube: data.youtube_url || '/',
    pagePartnerProgram: data.page_partner_program || '/',
    pagePromocodes: data.page_promocodes || '/',
    pageCompany: data.page_company || '/',
  }
}
export const errorSerializer = () => {
  const data = {
    init_state: {
      object: {
        title: '500',
        seo_title: '500',
      },
    },
  }
  return data
}

export const notFoundSerializer = (data) => {
  data.init_state.object = {
    title: '404',
    seo_title: '404',
  }
  return data
}

export const notificationsSerializers = (notification) => {
  return {
    event: notification.event,
    createdAt: notification.created_at,
    id: notification.id,
    isRead: notification.is_read,
    link: notification.info?.link || null,
    message: notification.info?.message,
    error:
      !!SOCKET_EVENTS.LOAD_ERROR ===
      notification.event /* TODO: add calc error and report error */,
  }
}

export const cargoSerializer = (cargo) => ({ ...cargo, ...cargo.info})

export const cargoSerializerMetalProfile = (cargo) => ({ ...cargoSerializer(cargo),  mp_rules: cargo?.mp_rules ? cargo?.mp_rules : INITIAL_MP_FIELDS})

export const groupsSerializer = (groups, serializeCargo) => {
  return groups.map((group, index) => {
    const cargoesNew = group.cargoes.map(serializeCargo)
    group.cargoes = cargoesNew
    group.sort = index + 1
    return group
  })
}


export const userCompanySerializer = (data) => {
  return {
    title: data.title || '',
    fact_address: data.fact_address || '',
    description: data.description || '',
    full_title: data.full_title || '',
    ur_address: data.ur_address || '',
    inn: data.inn || '',
    ogrn: data.ogrn || '',
    kpp: data.kpp || '',
    bank_title: data.bank_title || '',
    bic: data.bic || '',
    schet: data.schet || '',
    korschet: data.korschet || '',
    logo: data.logo || '',
  }
}

export const formDataSerializer = (obj) => {
  const fd = new FormData()
  for (let key in obj) {
    fd.append(key, obj[key])
  }
  return fd
}
/**
 * * получения списка гп для пагинации по загруженным гп
 * @func setProjectSystemIdList
 * @param {object} project
 * @param {object} response
 * @returns
 */
const setProjectSystemIdList = (project, response) => {
  if (!response.calculation_id || !Array.isArray(response.cargo_space_list)) {
    project.systemIdList = []
    project.currentSystemId = ''
    return
  }

  project.systemIdList = response.cargo_space_list
  // ! при подборе кол-ва гп может придти пустой массив гп если мы не сделали рассчет
  project.currentSystemId = response.cargo_space_list?.at(0)?.uid ?? ''
}

export const projectSerializer = (data) => {
  const {
    calculation_grade,
    id,
    last_calc,
    start_date,
    completion_date,
    cargo_spaces,
    first_visual,
    title,
    created_at,
    updated_at,
    is_calculating,
    calculation_time,
    calculation_status,
    userSort,
    demo,
    is_clone_cargo_space = false,
    cargo_space_count = null,
    is_axis_limit_enabled = false
  } = data
  const project = cloneDeep(DefaultProject)

  setProjectSystemIdList(project, data)

  project.calculation_grade = calculation_grade
  project.id = id
  project.title = title
  project.created_at = created_at
  project.updated_at = updated_at
  project.first_visual = first_visual
  project.is_calculating = is_calculating
  project.calculation_time = calculation_time
  project.cargoSpaces = cargo_spaces || []
  project.calculation_id = data?.calculation_id || ''
  project.calculation_status = calculation_status
  project.userSort = userSort || false
  project.demo = demo
  project.startDate = start_date
  project.completionDate = completion_date
  project.lastCalc = last_calc
  project.is_clone_cargo_space = is_clone_cargo_space
  project.cargo_space_count = cargo_space_count
  project.is_axis_limit_enabled = is_axis_limit_enabled
  
  return project
}
/**
 * * сериализация полeй distances
 * ! WARNING - мутирует входящий объект
 * @func cargoSpaceDistanceSerializer
 */
const cargoSpaceDistanceSerializer = (cargoSpaceValue) => {
  for (let i = 0; i < cargoSpaceValue.distances.length; i++) {
    const { distance } = cargoSpaceValue.distances[i]
    cargoSpaceValue.axes[i].distance = distance
  }
}
/**
 * * сериализации данных при создании - реадктировании гп
 * @func cargoSpacesSerializer
 */
export const cargoSpacesSerializer = (cargoSpaceValue) => {
  const unconvertibleKeys = [
    'title',
    'type',
    'distances',
    'axles',
    'tractor',
    'external_UID',
    'updated_at',
    'created_at'
  ]

  const convertibleCSValue = Object.entries(cargoSpaceValue).reduce(
    (acc, [k, v]) => {
      if (unconvertibleKeys.includes(k)) {
        acc[k] = v
      } else {
        acc[k] = Number(v)
      }
      return acc
    },
    {}
  )

  if (convertibleCSValue.distances) {
    cargoSpaceDistanceSerializer(convertibleCSValue)
  }

  return convertibleCSValue
}

export const companyMPSerializer = (mpList) => {
  if (mpList && Array.isArray(mpList)) {
    return mpList.map((el) =>
    Object.entries(el).reduce((acc, [key, val]) => {
      acc.id = key,
      acc.label = val
      return acc
    }, {}))
  } else {
    return []
  }
}
