import * as yup from 'yup'
import { getValidateText } from '../ValidateText'
import { ERROR_NUMBERS, INTL_ERROR_MESSAGE } from '../../const'

const commonStringValidation = yup
  .string()
  .trim()
  .max(
    ERROR_NUMBERS.MAX_SHEET_TITLE,
    getValidateText('number', 'moreThan', ERROR_NUMBERS.MAX_SHEET_TITLE)
  )
  .min(
    ERROR_NUMBERS.MIN_STRING_NUMBER,
    getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
  )

const uploadFileGazpromProjectSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(
      ERROR_NUMBERS.MAX_XLSX_TITLE_COLUMN,
      getValidateText('title', 'max', ERROR_NUMBERS.MAX_XLSX_TITLE_COLUMN)
    )
    .min(
      ERROR_NUMBERS.MIN_STRING_NUMBER,
      getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  column_title: yup
    .string()
    .trim()
    .max(
      ERROR_NUMBERS.MAX_SHEET_TITLE,
      getValidateText('number', 'moreThan', ERROR_NUMBERS.MAX_SHEET_TITLE)
    )
    .min(
      ERROR_NUMBERS.MIN_STRING_NUMBER,
      getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  column_article: yup
    .string()
    .trim()
    .max(
      ERROR_NUMBERS.MAX_SHEET_TITLE,
      getValidateText('number', 'moreThan', ERROR_NUMBERS.MAX_SHEET_TITLE)
    )
    .min(
      ERROR_NUMBERS.MIN_STRING_NUMBER,
      getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
    ),
  column_count: yup
    .string()
    .trim()
    .max(
      ERROR_NUMBERS.MAX_SHEET_TITLE,
      getValidateText('number', 'moreThan', ERROR_NUMBERS.MAX_SHEET_TITLE)
    )
    .min(
      ERROR_NUMBERS.MIN_STRING_NUMBER,
      getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
    ),
  column_mass: yup.string().when('type_vgh', {
    is: 'search_by_name',
    then: commonStringValidation,
    otherwise: commonStringValidation.required(INTL_ERROR_MESSAGE.REQUIRED)
  }),
  start_from_line: yup
    .number()
    .min(
      ERROR_NUMBERS.MIN_STRING_NUMBER,
      getValidateText('number', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
    ).max(
      ERROR_NUMBERS.MAX_NUMBER,
      getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  dimensions_unit: yup.string().required(INTL_ERROR_MESSAGE.REQUIRED),
  type_vgh: yup.string().required(INTL_ERROR_MESSAGE.REQUIRED),
  // ? variaty
  delimiter: yup.string().when('type_vgh', {
    is: 'in_one_cell',
    then: yup
      .string()
      .trim()
      .max(
        ERROR_NUMBERS.MAX_SHEET_TITLE,
        getValidateText('number', 'moreThan', ERROR_NUMBERS.MAX_SHEET_TITLE)
      )
      .min(
        ERROR_NUMBERS.MIN_STRING_NUMBER,
        getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
      )
      .required(INTL_ERROR_MESSAGE.REQUIRED)
  }),
  column_sizes: yup.string().when('type_vgh', {
    is: 'in_one_cell',
    then: yup
      .string()
      .trim()
      .max(
        ERROR_NUMBERS.MAX_SHEET_TITLE,
        getValidateText('number', 'moreThan', ERROR_NUMBERS.MAX_SHEET_TITLE)
      )
      .min(
        ERROR_NUMBERS.MIN_STRING_NUMBER,
        getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
      )
      .required(INTL_ERROR_MESSAGE.REQUIRED)
  }),
  column_length: yup.string().when('type_vgh', {
    is: 'in_three_cells',
    then: yup
      .string()
      .trim()
      .max(
        ERROR_NUMBERS.MAX_SHEET_TITLE,
        getValidateText('number', 'moreThan', ERROR_NUMBERS.MAX_SHEET_TITLE)
      )
      .min(
        ERROR_NUMBERS.MIN_STRING_NUMBER,
        getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
      )
      .required(INTL_ERROR_MESSAGE.REQUIRED)
  }),
  column_width: yup.string().when('type_vgh', {
    is: 'in_three_cells',
    then: yup
      .string()
      .trim()
      .max(
        ERROR_NUMBERS.MAX_SHEET_TITLE,
        getValidateText('number', 'moreThan', ERROR_NUMBERS.MAX_SHEET_TITLE)
      )
      .min(
        ERROR_NUMBERS.MIN_STRING_NUMBER,
        getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
      )
      .required(INTL_ERROR_MESSAGE.REQUIRED)
  }),
  column_height: yup.string().when('type_vgh', {
    is: 'in_three_cells',
    then: yup
      .string()
      .trim()
      .max(
        ERROR_NUMBERS.MAX_SHEET_TITLE,
        getValidateText('number', 'moreThan', ERROR_NUMBERS.MAX_SHEET_TITLE)
      )
      .min(
        ERROR_NUMBERS.MIN_STRING_NUMBER,
        getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
      )
      .required(INTL_ERROR_MESSAGE.REQUIRED)
  }),
  sheet_title: yup
    .string()
    .trim()
    .max(
      ERROR_NUMBERS.MAX_SECONDARY_XLSX_TITLE_COLUMN,
      getValidateText('title', 'max', ERROR_NUMBERS.MAX_SECONDARY_XLSX_TITLE_COLUMN)
    )
    .min(
      ERROR_NUMBERS.MIN_STRING_NUMBER,
      getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  weight_unit: yup.string().required(INTL_ERROR_MESSAGE.REQUIRED)
})

export default uploadFileGazpromProjectSchema
// column_article: null,
// column_count: null,
// column_height: null,
// column_length: null,
// column_mass: null,
// column_sizes: null,
// column_title: null,
// column_width: null,
// delimiter: null,
// is_rotate_y: false,
// is_use_last: false,
// stacking: false,
// start_from_line: null,
// title: null,
// turnover: false,
// type_vgh: null,
// unit: null,
