import api from '@/api/services/project'
/* eslint-disable camelcase */
export const units = [
  {
    id: 1,
    label: {
      id: 'mm_unit',
      defaultMessage: 'миллиметры'
    },
    value: 'mm'
  },
  {
    id: 2,
    label: {
      id: 'cm_unit',
      defaultMessage: 'сантиметры'
    },
    value: 'cm'
  },
  {
    id: 3,
    label: {
      id: 'm_unit',
      defaultMessage: 'метры'
    },
    value: 'm'
  }
]

export const vghs = [
  {
    id: 1,
    label: {
      id: 'in_one_cell',
      defaultMessage: 'в одну ячейку'
    },
    value: 'in_one_cell'
  },
  {
    id: 2,
    label: {
      id: 'in_three_cells',
      defaultMessage: 'в три ячейки'
    },
    value: 'in_three_cells'
  },
  {
    id: 3,
    label: {
      id: 'search_by_name',
      defaultMessage: 'искать по названию'
    },
    value: 'search_by_name'
  }
]

export const atomicSelect = [
  {
    id: 1,
    label: {
      id: 'yes',
      defaultMessage: 'Да'
    },
    value: true
  },
  {
    id: 2,
    label: {
      id: 'no',
      defaultMessage: 'Нет'
    },
    value: false
  }
]

export const weightUnits = [
  {
    id: 1,
    label: {
      id: 'kg_full',
      defaultMessage: 'кг'
    },
    value: 'kg'
  },
  {
    id: 2,
    label: {
      id: 'ton_full',
      defaultMessage: 'т'
    },
    value: 'ton'
  },
  {
    id: 3,
    label: {
      id: 'kg_total',
      defaultMessage: 'кг суммарно'
    },
    value: 'kg_total'
  },
  {
    id: 4,
    label: {
      id: 'ton_total',
      defaultMessage: 'т суммарно'
    },
    value: 'ton_total'
  }
]

export const serializeOnlyNeesValues = (values) => {
  return {
    column_article: values.column_article,
    column_count: values.column_count,
    column_height: values.column_height,
    column_length: values.column_length,
    column_mass: values.column_mass,
    column_sizes: values.column_sizes,
    column_title: values.column_title,
    column_width: values.column_width,
    delimiter: values.delimiter,
    is_rotate_y: values.is_rotate_y,
    stacking: values.stacking,
    start_from_line: values.start_from_line,
    title: values.title,
    turnover: values.turnover,
    type_vgh: values.type_vgh,
    dimensions_unit: values.dimensions_unit,
    sheet_title: values.sheet_title,
    weight_unit: values.weight_unit
  }
}

export const fetchergetTemplatesExel = (args = {}) => {
  const { page, page_size, search } = args
  return api.getTemplatesExel({
    page,
    page_size,
    search
  })
}
