import React from 'react'
import { Route, Switch } from 'react-router-dom'
import UniversalPage from './pages/UniversalPage'
import Modals from './views/BaseModal'
import Sidebars from '@/apps/Sidebars'
import '@garpix/garpix-web-components/dist/garpix-web-components/garpix-web-components.css'
import { Helmet } from 'react-helmet-async'
import { runtimeConfig } from './config'
import Locale from './apps/components/Locale'

const App = () => {
  return (
    <>
      <Helmet>
        <link rel='preconnect' href='http://code-ya.jivosite.com' />
        <link rel='preconnect' href='https://www.google-analytics.com' />
        <meta name='msapplication-TileColor' content='#057FF7' />
        <meta name='msapplication-config' content='/icons/browserconfig.xml' />
        <meta name='theme-color' content='#F8FBFF' />
        <meta name='yandex-verification' content='5718cc205489583a' />
        <noscript>
          {` 
            <img src="https://vk.com/rtrg?p=VK-RTRG-1710756-b37J4" style="position:fixed; left:-999px;" alt=""/>
            `}
        </noscript>
        <noscript>
          {
            "<div><img src='https://mc.yandex.ru/watch/86465851' style='position:absolute; left:-9999px;' alt='' /></div>"
          }
        </noscript>
        <script
          type='text/javascript'
          src={`https://www.google.com/recaptcha/api.js?render=${runtimeConfig.RAZZLE_RECAPTCHA_KEY}`}
          async
        />
        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=UA-145470300-9'
        />
        <script>
          {`if('%NODE_ENV%' === '%RAZZLE_APP_ENVIRONMENT%'){window.dataLayer = window.dataLayer || []
          function gtag() {
          dataLayer.push(arguments)
        }
          gtag('js', new Date())
          gtag('config', 'UA-145470300-9')}`}
        </script>
      </Helmet>
      <Locale>
        <Switch>
          <Route path='*' component={UniversalPage} />
        </Switch>
        <Modals />
        <Sidebars />
      </Locale>
    </>
  )
}

export default App
