export const sidebarModule = (store) => {
  store.on('@init', () => ({
    sidebar: {
      currentShowingBlock: 'sidebar',
      isStartClosingAnimation: false,
      isOpen: false,
      isCollapse: false,
      sidebarProps: {},
      type: null,
    },
  }))

  store.on('sidebar/open', (_, { type = 'empty', sidebarProps = {} }) => {
    return {
      sidebar: { ..._, type: type, sidebarProps: sidebarProps, isOpen: true },
    }
  })

  store.on('sidebar/close', (_) => {
    return {
      sidebar: {
        ..._,
        sidebarProps: {},
        isOpen: false,
        type: null,
        isStartClosingAnimation: false,
      },
    }
  })

  store.on('sidebar/collapse', (_, { isCollapse = true }) => {
    return {
      sidebar: {
        ..._,
        isCollapse,
      },
    }
  })

  store.on('sidebar/expand', (_, { isCollapse = false }) => {
    return {
      sidebar: {
        ..._,
        isCollapse,
      },
    }
  })

  store.on('sidebar/setAnimationClosing', (_, { isClosing = false }) => {
    return {
      sidebar: {
        ..._,
        isStartClosingAnimation: isClosing,
      },
    }
  })
}

export default sidebarModule
