import React, { useRef, useState } from 'react'
import ChangeUserAvatar from '../../../views/BaseModal/ModalContent/ChangeUserAvatar'
import { USER_AVATAR_CONST } from '../../../const'
import { EMPTY_CONSTS } from '../../RTF/settings'
import { convertError } from '../../../utils'
import usePage from '../../../hooks/usePage'
import { useLocation } from 'react-router'
import useTranslate from '../../../hooks/useTranslate'

const ChangeUserAvatarModal = ({ handelSendPhoto, handleClose }) => {
  const { translate, intl } = useTranslate()
  const [err, setErr] = useState(EMPTY_CONSTS.STR)
  const [buttonLoader, setButtonLoader] = useState(false)
  const [avatarSettings, setAvatarSettings] = useState({
    scale: USER_AVATAR_CONST.SCALE,
    image: EMPTY_CONSTS.NULL
  })
  const avatartEditor = useRef(null)

  const { baseSlug } = usePage()
  const { pathname } = useLocation()

  let zoom = avatarSettings.scale

  const setZoomStepAndCheckLimit = (e) => {
    zoom =
      e.deltaY < 0
        ? (zoom += USER_AVATAR_CONST.STEP)
        : (zoom -= USER_AVATAR_CONST.STEP)

    zoom = Math.min(
      Math.max(USER_AVATAR_CONST.MIN_SCALE, zoom),
      USER_AVATAR_CONST.MAX_SCALE
    )
    return zoom
  }

  const zoomPhoto = (e) => {
    const zoomWhithStep = setZoomStepAndCheckLimit(e)
    setAvatarSettings((prev) => ({
      ...prev,
      scale: zoomWhithStep
    }))
  }

  const onSave = async () => {
    setButtonLoader(true)
    try {
      const canvasImage = avatartEditor.current.getImage()
      const imageB64 = canvasImage.toDataURL()
      const imageBlob = await new Promise(resolve => canvasImage.toBlob(resolve, 'image/png'))
      const file = new window.File([imageBlob], 'test.png', { type: 'image/png' })

      await handelSendPhoto({ file, imageB64 })
      handleClose()
    } catch (error) {
      setErr(translate(convertError(error)))
    } finally {
      setButtonLoader(false)
    }
  }

  const handelRemove = () => {
    setAvatarSettings((prev) => ({
      ...prev,
      image: null
    }))
  }

  const onChangeImage = (e) => {
    if (e.target.files[0].size > USER_AVATAR_CONST.MAX_SIZE_FILE) {
      setErr(intl.formatMessage({
        id: 'modals.changeUserAvatar.maxSizeError',
        defaultMessage: 'слишком большой файл'
      }))
      return
    }
    setAvatarSettings((prev) => ({
      ...prev,
      image: e.target.files[0]
    }))
  }

  return (
    <ChangeUserAvatar
      onSave={onSave}
      buttonLoader={buttonLoader}
      err={err}
      baseSlug={baseSlug}
      pathname={pathname}
      zoomPhoto={zoomPhoto}
      onChangeImage={onChangeImage}
      handelRemove={handelRemove}
      avatarSettings={avatarSettings}
      ref={avatartEditor}
    />
  )
}

export default ChangeUserAvatarModal
