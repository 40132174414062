import React from 'react'
import qs from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import { useModalIntlContent, useModalStore } from '../hooks/Modals'
import usePage from '../hooks/usePage'
import { cargoApiService, cargoSpaceApiService, projectApiService } from '../api/services'
import { convertError, createQueryURL } from '../utils'
import { ENTITY_TYPES, MODAL_TYPES } from '../const'
import AddComponentView from '../views/AddComponentView'

const request = {
  project: projectApiService.projectTariff,
  cargo_space: cargoSpaceApiService.cargoSpaceTariff,
  cargo: cargoApiService.cargoTariff
}

const AddComponent = ({ addButtonText, type }) => {
  const history = useHistory()
  const { search } = useLocation()
  const { currentUser } = useStoreon('currentUser')
  const { baseSlug } = usePage()
  const { openModal, contentTypes } = useModalStore()
  const tariffLimitModalContent = useModalIntlContent(contentTypes.tariffLimit)
  const { cargo_space_type: cargoSpaceType } = qs.parse(search)
  const cargoSpaceList = currentUser?.current_tariff?.cargo_space_list
  const isSpaceAvailable = (cargoSpaceList && cargoSpaceList.includes(cargoSpaceType)) || cargoSpaceType === undefined
  const parsed = qs.parse(history.location.search)

  /**
   * Редирект на страницу создания грузового пространства если нет ограничений. Если ограничения то модалка с лимитами.
   *
   * @function goToCreateCargoSpacePage
   */
  const goToCreateCargoSpacePage = () => {
    if (!cargoSpaceList.length || !isSpaceAvailable) {
      openModal(MODAL_TYPES.TARIFF_LIMIT, tariffLimitModalContent)
      return
    }
    const params = { cargo_space_type: cargoSpaceType }
    parsed.owner_id && Object.assign(params, { owner_id: parsed.owner_id })
    history.push(createQueryURL(baseSlug.pageCreateCargoSpace, params))
  }

  /**
   * Редирект на страницу создание груза.
   *
   * @function goToCreateCargoPage
   */
  const goToCreateCargoPage = () => {
    const params = {}
    parsed.owner_id && Object.assign(params, { owner_id: parsed.owner_id })
    history.push(createQueryURL(baseSlug.pageCreateCargo, params))
  }

  /**
   * Метод для кнопки доавления сущностей.
   * Успех - роутинг по страницам создания сущностей в зависимости от типа.
   * Ошибка - показываем модалку с ошибкой(лимиты)
   *
   * @function handleAdd
   */
  const handleAdd = async () => {
    try {
      await request[type]()
      switch (type) {
        case ENTITY_TYPES.CARGO:
          goToCreateCargoPage()
          return
        case ENTITY_TYPES.CARGO_SPACE: {
          goToCreateCargoSpacePage()
          return
        }
        case ENTITY_TYPES.PROJECT:
          openModal(MODAL_TYPES.CREATE_PROJECT)
          return
      }
    } catch (e) {
      const error = convertError(e)
      openModal(MODAL_TYPES.TARIFF_LIMIT, { ...tariffLimitModalContent, description: error })
    }
  }

  return (
    <AddComponentView handleAdd={handleAdd} addButtonText={addButtonText} type={type} />
  )
}

export default AddComponent
