import React, { useState } from 'react'
import useTranslate, { TIME_UNITS } from '../../../../hooks/useTranslate'

import TariffTabGroup from '../TariffTabGroup'
import TariffPanelSlider from './TariffPanelSlider'
import TariffCard from '../TariffCardView'

import { TARIFF_TAB_GROUP_TAB, TARIFF_TAB_GROUP_THEME } from '../../../../const'
import { useMedia } from 'react-use'

const TariffPanel = (props) => {
  const {
    periods,
    activeTab,
    setActiveTab,
    tariffs,
    pressedTariff,
    handleBuyTariff,
    handlePickCard,
    className,
    showButton,
    baseTariff,
    tableLinkOptions,
    isProfile = false,
    tariffPanelTheme = null
  } = props
  const isAdaptive = useMedia('(max-width: 768px)')
  const [needSlide, setNeedSlide] = useState(true)
  const { intl } = useTranslate()

  return (
    <TariffTabGroup
      noScrollControls
      theme={!isProfile && TARIFF_TAB_GROUP_THEME.LANDING}
      className={className}
    >
      {periods.map((el) => {
        const { id, amount, time_units: timeUnits } = el
        return (
          <TariffTabGroup.Tab
            key={id}
            panel={id}
            active={id === activeTab.id || null}
            theme={TARIFF_TAB_GROUP_TAB.LANDING}
            onClick={() => setActiveTab(el)}
          >
            {intl.formatNumber(amount, {
              style: 'unit',
              unit: TIME_UNITS[timeUnits] || TIME_UNITS.months,
              unitDisplay: isAdaptive ? 'narrow' : 'long'
            })}
          </TariffTabGroup.Tab>
        )
      })}
      {Object.keys(tariffs).map((key) => (
        <TariffTabGroup.Panel key={key} name={key} theme={tariffPanelTheme}>
          {!isProfile
            ? (
              <TariffPanelSlider
                needSlide={needSlide}
                setNeedSlide={setNeedSlide}
                tariffs={tariffs[key]}
                tariffKey={key}
                isProfile={isProfile}
                showButton={showButton}
                handlePickCard={handlePickCard}
                baseTariff={baseTariff}
                handleBuyTariff={handleBuyTariff}
                pressedTariff={pressedTariff}
                periods={periods}
              />
              )
            : (
                tariffs[key].map((tariff) => {
                  const period = periods.find(({ id }) => id === +key)
                  const isUserTariff = baseTariff === tariff.tariff_id
                  return (
                    <TariffCard
                      isUserTariff={isUserTariff}
                      key={tariff.tariff_id}
                      active={pressedTariff?.tariff_id === tariff.tariff_id}
                      handleBuyTariff={handleBuyTariff}
                      tariff={tariff}
                      amount={period.amount}
                      timeUnit={period?.time_units}
                      handlePickCard={handlePickCard}
                      showButton={showButton}
                      isProfile={isProfile}
                    />
                  )
                })
              )}
        </TariffTabGroup.Panel>
      ))}
      <TariffTabGroup.Link {...tableLinkOptions}>
        {intl.formatMessage({
          id: 'tariffComparison',
          defaultMessage: 'Сравнение тарифов'
        })}
      </TariffTabGroup.Link>
    </TariffTabGroup>
  )
}

export default TariffPanel
