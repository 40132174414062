import React, { useState, useLayoutEffect } from 'react'
import ModalView from '../../../ModalView'
import Avatar from './Avatar'
import FileInput from '../../../FileInput'
import { BUTTON_THEME, LOADER_THEME } from '../../../../const'
import Loader from '../../../Loader'
import { FormattedMessage, useIntl } from 'react-intl'
import style from '../styles/index.module.scss'

const descriptionType = {
  select: {
    id: 'modals.changeUserAvatar.select',
    defaultMessage: 'Поддерживаемые форматы: jpg, jpeg, png. <br> Изображение не должно превышать 20 MB.'
  },
  profile: {
    id: 'modals.changeUserAvatar.profile',
    defaultMessage: 'Выбранная область будет отображаться в вашем профиле.'
  },
  company: {
    id: 'modals.changeUserAvatar.company',
    defaultMessage: 'Выбранная область будет отображаться на странице вашей компании.'
  }
}

const ChangeUserAvatar = React.forwardRef(
  (
    {
      onSave,
      buttonLoader,
      avatarSettings,
      baseSlug,
      pathname,
      handelRemove,
      onChangeImage,
      zoomPhoto,
      err
    },
    ref
  ) => {
    const intl = useIntl()
    const [description, setDescription] = useState(descriptionType.select)
    useLayoutEffect(() => {
      if (!avatarSettings.image) {
        setDescription(descriptionType.select)
      } else if (avatarSettings.image && baseSlug.pageProfile === pathname) {
        setDescription(descriptionType.profile)
      }
    }, [avatarSettings.image])

    return (
      <>
        <ModalView.Title>
          <FormattedMessage
            id='modals.changeUserAvatar.title'
            defaultMessage='Выберите изображение'
          />
        </ModalView.Title>
        <div className={style.user_avatar__description}>
          <ModalView.Description description={intl.formatMessage(description)} />
        </div>
        {avatarSettings.image
          ? (
            <>
              <Avatar
                zoomPhoto={zoomPhoto}
                avatarSettings={avatarSettings}
                ref={ref}
              />
              <p className={style.user_avatar__description_setting}>
                <FormattedMessage
                  id='modals.changeUserAvatar.description'
                  defaultMessage='Чтобы увеличить/уменьшить маштаб фото используйте колесо мыши'
                />
              </p>
              {err && <p className={style.user_avatar__err}>{err}</p>}
              <ModalView.Buttons>
                <ModalView.Buttons.Button
                  onClick={handelRemove}
                  theme={BUTTON_THEME.STROKE_ICON.SMALL}
                >
                  <FormattedMessage
                    id='modals.changeUserAvatar.closeButton'
                    defaultMessage='Назад'
                  />
                </ModalView.Buttons.Button>
                <ModalView.Buttons.Button
                  disabled={buttonLoader}
                  onClick={onSave}
                  className={style.user_avatar__save}
                >
                  {
                      buttonLoader
                        ? <Loader theme={LOADER_THEME.BUTTON} />
                        : <FormattedMessage
                            id='save'
                            defaultMessage='Сохранить'
                          />
                  }
                </ModalView.Buttons.Button>
              </ModalView.Buttons>
            </>
            )
          : (
            <>
              <FileInput
                accept='.jpg,.png,.jpeg'
                handleLoad={onChangeImage}
                text={intl.formatMessage({
                  id: 'modals.changeUserAvatar.fileInputText',
                  defaultMessage: 'Выбрать файл'
                })}
              />
            </>
            )}
      </>
    )
  }
)

export default ChangeUserAvatar
