import React, { useMemo } from 'react'
import qs from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import Media from 'react-media'
import { useStoreon } from 'storeon/react'
import { useIntl } from 'react-intl'

import DesktopTableCard from './DesktopTableCard'
import MobileTableCard from './MobileTableCard'

import usePage from '@/hooks/usePage'
import { useModalIntlContent, useModalStore } from '@/hooks/Modals'
import useLimit from '@/hooks/useLimit'

import { createQueryURL, openUrl } from '@/utils'
import { ENTITY_TYPES } from '@/const'

import style from '../styles/index.module.scss'

const Body = ({ type, results, ...props }) => {
  const intl = useIntl()
  const history = useHistory()
  const { baseSlug, page } = usePage()
  const { currentUser } = useStoreon('currentUser')
  const { openModal, contentTypes } = useModalStore()
  const limitModalContent = useModalIntlContent(contentTypes.tariffLimit)
  const demoLimitModalContent = useModalIntlContent(contentTypes.demoLimit)
  const noChangeCargoSpaceModalContent = useModalIntlContent(contentTypes.noChangeCargoSpace)
  const { isAvailableSpace, isFreeTariffLimit, isAvailableDemoEditing } = useLimit()
  const location = useLocation()
  const parsed = qs.parse(location.search)

  const { global: { is_show_tariff: isShowTariff = false } } = page.init_state

  /**
   * Метод создания урлов в зависимости от типа
   *
   * @constructor
   * @param {object} item Элемент списочной страницы
   * @return {string}
   */
  const getPath = (item) => {
    if (type === ENTITY_TYPES.CARGO) {
      const params = {
        id: item.id
      }
      parsed.owner_id && Object.assign(params, { owner_id: parsed.owner_id })
      return createQueryURL(baseSlug.pageUpdateCargo, params)
    } else if (type === ENTITY_TYPES.CARGO_SPACE) {
      const params = {
        cargo_space_type: item.cargo_space_type,
        id: item.id
      }
      parsed.owner_id && Object.assign(params, { owner_id: parsed.owner_id })

      return createQueryURL(baseSlug.pageUpdateCargoSpace, params)
    } else return item?.absolute_url
  }
  const checkLimit = item => {
    if (isFreeTariffLimit) {
      openModal('tariffLimit', limitModalContent)
      return true
    }
    if (!isAvailableDemoEditing && item.demo) {
      openModal('tariffLimit', demoLimitModalContent)
      return true
    }
    if (type === ENTITY_TYPES.CARGO_SPACE) {
      const isValidSpace = isAvailableSpace(item.cargo_space_type)
      if (!isValidSpace) {
        openModal('tariffLimit', limitModalContent)
        return true
      }
      if (!item.user) {
        openModal('commonConfirm', noChangeCargoSpaceModalContent)
        return true
      }
    }
    return false
  }

  /**
   * Метод для редиректа на страницу обновления деталки списочного елемента. Если ограничения - показываем модалку.
   * @param item
   */
  const handleClick = (item) => {
    if (checkLimit(item)) {
      return
    }
    history.push(getPath(item))
  }
  const handleContextMenu = (item) => {
    const limit = checkLimit(item)
    if (!limit) {
      const path = getPath(item)
      openUrl(path)
    }
  }

  const getTime = (date) => intl.formatDate(date, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  })

  const serializedResults = useMemo(() => results.map(res => ({
    ...res,
    download: true,
    demo: isShowTariff && currentUser.current_tariff && res.demo,
    created_at: getTime(res.created_at),
    updated_at: getTime(res.updated_at)

  })), [results, getTime])

  return (
    <tbody className={style.table_body}>
      {serializedResults.map((item, index, arr) => {
        return (
          <React.Fragment key={item.id}>
            <Media queries={{ small: { maxWidth: 1200 } }}>
              {(matches) =>
                matches.small
                  ? (
                    <MobileTableCard
                      item={item}
                      type={type}
                      handleClick={() => handleClick(item)}
                      handleContextMenu={() => handleContextMenu(item)}
                      results={arr}
                      indexRow={index}
                      {...props}
                    />
                    )
                  : (
                    <DesktopTableCard
                      item={item}
                      type={type}
                      handleClick={() => handleClick(item)}
                      handleContextMenu={() => handleContextMenu(item)}
                      results={arr}
                      indexRow={index}
                      {...props}
                    />
                    )}
            </Media>
          </React.Fragment>
        )
      })}
    </tbody>
  )
}

export default Body
