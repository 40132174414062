import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import Button from '@/views/Button'
import { editIcon, deleteIcon } from '@/images'
import { BUTTON_THEME } from '@/const'
import style from './styles/index.module.scss'

// column_article: 'йцй'
// column_count: '11'
// column_height: '11'
// column_length: '11'
// column_mass: '11'
// column_sizes: '1'
// column_title: 'йцйвввввфф'
// column_width: '11'
// delimiter: '1'
// dimensions_unit: 'mm'
// id: 26
// is_rotate_y: true
// sheet_title: 'Грузы'
// stacking: true
// start_from_line: 1
// title: 'ффвфыв'
// turnover: true
// type_vgh: 'in_three_cells'
// weight_unit: 'kg'

const Item = ({ id, title, handleClickDelete, handleClickEdit }) => {
  return (
    <div className={style.wrapper}>
      <div className={style.wrapper__title}>{title}</div>
      <div className={style.wrapper__icons}>
        <Button
          onClick={() => handleClickEdit({ id, title })}
          theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
        >
          <GxIcon src={editIcon} className={style.wrapper__icon} />
        </Button>
        <Button
          onClick={() => handleClickDelete({ id, title })}
          theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
        >
          <GxIcon src={deleteIcon} className={style.wrapper__icon_delete} />
        </Button>
      </div>
    </div>
  )
}

export default Item
