import {Api} from '../../index.js'
import { runtimeConfig } from '../../../config'


class CalculationApiService extends Api {
    createDesignProject = async (params) => {
        const res = await this.post('/calculation/create_designed/', params);
        return res.data;
    };

    updateDesignProject = async ({ calcID, ...params }) => {
        const res = await this.patch(`/calculation/${calcID}/update_designed/`, params);
        return res.data;
    };

    sendPacker = async (params) => {
        const res = await this.post('/calculation/', params);
        return res.data;
    };

    cancelCalculation = async ({ id }) => {
        const res = await this.post(`/calculation/${id}/cancel/`);
        return res.data;
    };

    getCalcResult = async ({ calculation_id, uid }) => {
        const res = await this.get(`/calculation/${calculation_id}/cargo_space/${uid}/`);
        return res.data;
    };

    getCalcResultTopSpace = async ({ calculation_id, uid, pallet_id: pallet_uid }) => {
        const res = await this.get(
            `/calculation/${calculation_id}/top_space/${uid}/`, { pallet_uid }
        );
        return res.data;
    };

    getCalcResultGroup = async ({ calculation_id }) => {
        const res = await this.get(`/calculation/${calculation_id}/cargoes/`);
        return res.data;
    };

    getHistory = async ({ page, page_size, project_id, favorite }) => {
        const res = await this.get('/calculation/history/', {
            page,
            page_size,
            favorite,
            project_id,
        });
        return res.data;
    };

    getProjectCalculation = async (calculation_id) => {
        const res = await this.get(`/calculation/${calculation_id}/`);
        return res.data;
    };

    updateProjectHistory = async ({ id, favorite }) => {
        const res = await this.patch(`/calculation/${id}/`, {
            favorite,
        });
        return res.data;
    };

    deleteHistory = async ({ id }) => {
        const res = await this.delete(`/calculation/${id}/`);
        return res.data;
    };

    getReportPage = async ({ id }) => {
        const res = await this.get(`/calculation/${id}/calculation_report_page/`);
        return res.data;
    };

    createFullPivotReport = async ({ id, params }) => {
        const res = await this.post(`/calculation/${id}/full_pivot_report/`, params);
        return res.data;
    };

    createReportExcludingPallets = async ({ id, params }) => {
        const res = await this.post(
            `/calculation/${id}/pivot_report_excluding_pallets/`,
            params
        );
        return res.data;
    };

    createPivotReport = async ({ id, params }) => {
        const res = await this.post(
            `/calculation/${id}/calculation_pivot_report/`,
            params
        );
        return res.data;
    };

    createReport = async ({ id, params }) => {
        const res = await this.post(`/calculation/${id}/calculation_report/`, params);
        return res.data;
    };

    move_create = async ({ id_calculation, params }) => {
        const res = await this.post(
            `/calculation/${id_calculation}/move_create/`,
            params
        );
        return res.data;
    };

    move_update = async ({ id_calculation, params }) => {
        const res = await this.patch(
            `/calculation/${id_calculation}/move_update/`,
            params
        );
        return res.data;
    };

    recalculate = async (id) => {
        const res = await this.post(`/calculation/${id}/recalculation/`);
        return res.data;
    };

    setGradeCalculation = async (id, grade) => {
        const res = await this.patch(`/calculation/${id}/set_grade/`, { grade });
        return res.data;
    };

    gradeCommentRequest = async ({ id, message = '', ...props }) => {
        const res = await this.patch(`/calculation/${id}/add_comment/`, {
            message,
            ...props,
        });
        return res.data;
    };

}

export default new CalculationApiService(runtimeConfig.API_URL)