const DEFAULT_ENV = {
  IS_DEV: process.env.NODE_ENV === 'development'
}

export const runtimeConfig =
  typeof window === 'undefined'
    ? {
        // server
        ...DEFAULT_ENV,
        API_URL: process?.env?.RAZZLE_API_URL ?? '',
        API_URL_WSS: process?.env?.RAZZLE_API_URL_WSS ?? '',
        RAZZLE_APP_ENVIRONMENT: process?.env?.RAZZLE_APP_ENVIRONMENT ?? '',
        RAZZLE_APP_LOCALE: process?.env?.RAZZLE_APP_LOCALE ?? '',
        RAZZLE_IS_FOR_ASSESSMENT_ML: process?.env?.RAZZLE_IS_FOR_ASSESSMENT_ML ?? '',
        RAZZLE_RECAPTCHA_KEY: process?.env?.RAZZLE_RECAPTCHA_KEY ?? ''
      }
    : {
        // client
        // @ts-expect-error
        ...DEFAULT_ENV,
        API_URL: window?.env?.API_URL !== undefined ? window?.env?.API_URL : '',
        API_URL_WSS:
          window?.env?.API_URL_WSS !== undefined ? window?.env?.API_URL_WSS : '',
        RAZZLE_APP_ENVIRONMENT:
          window?.env?.RAZZLE_APP_ENVIRONMENT !== undefined
            ? window?.env?.RAZZLE_APP_ENVIRONMENT
            : '',
        RAZZLE_APP_LOCALE: window?.env?.RAZZLE_APP_LOCALE !== undefined ? window?.env?.RAZZLE_APP_LOCALE : '',
        RAZZLE_IS_FOR_ASSESSMENT_ML:
          window?.env?.RAZZLE_IS_FOR_ASSESSMENT_ML !== undefined
            ? window?.env?.RAZZLE_IS_FOR_ASSESSMENT_ML === 'true'
            : false,
        RAZZLE_RECAPTCHA_KEY: window?.env?.RAZZLE_RECAPTCHA_KEY !== undefined ? window?.env?.RAZZLE_RECAPTCHA_KEY : ''
      }
