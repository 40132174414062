// ------- Layout -------
import logo from './logo.svg'
import logoPro from './logoPro.svg'
import newLogoPro from './newLogoPro.png'
import logoText from './logoText.svg'
import garpixLogo from './icons/garpix-logo.svg'
import emptyReports from './emptyReports.svg'
// ------- Icons 16px -------
import downIcon from './icons/downIcon.svg'
import leftIcon from './icons/leftIcon.svg'
import rightIcon from './icons/rightIcon.svg'
import errorIcon from './icons/errorIcon.svg'
import doneIcon from './icons/doneIcon.svg'
import playIcon from './icons/playIcon.svg'
import repeatIcon from './icons/repeatIcon.svg'
import closeSmallIcon from './icons/closeSmallIcon.svg'
import doubleLeftIcon from './icons/doubleLeftIcon.svg'
import doubleRightIcon from './icons/doubleRightIcon.svg'
import okIcon from './icons/okIcon.svg'
import failIcon from './icons/failIcon.svg'
import palletAndBoxIcon from './palletAndBoxIcon.svg'
import palletIconProject from './palletIconProject.svg'
import cargosOutlineIcon from './cargosOutlineIcon.svg'

// ------- Icons 24px -------
import updateIcon from './icons/update.svg'
import reportIcon from './icons/report.svg'
import workBack from './work-back.svg'
import arrowIcon from './icons/arrow.svg'
import closeCircle from './icons/close-circle.svg'
import russianFlag from './icons/russianFlag.svg'
import attentionBanner from './icons/attentionBanner.svg'
import notificationsIcon from './icons/notificationsIcon.svg'
import downloadIcon from './icons/downloadIcon.svg'
import helpIcon from './icons/helpIcon.svg'
import profileIcon from './icons/profileIcon.svg'
import exitIcon from './icons/exitIcon.svg'
import searchIcon from './icons/searchIcon.svg'
import fileIcon from './icons/fileIcon.svg'
import plusIcon from './icons/plusIcon.svg'
import moreIcon from './icons/moreIcon.svg'
import editIcon from './icons/editIcon.svg'
import copyIcon from './icons/copyIcon.svg'
import shareIcon from './icons/shareIcon.svg'
import deleteIcon from './icons/deleteIcon.svg'
import closeIcon from './icons/closeIcon.svg'
import spacesIcon from './icons/spacesIcon.svg'
import cargoIcon from './icons/cargoIcon.svg'
import calculationIcon from './icons/calculationIcon.svg'
import historyIcon from './icons/historyIcon.svg'
import addCargoIcon from './icons/addCargoIcon.svg'
import minusSmallIcon from './icons/minusSmallIcon.svg'
import plusSmallIcon from './icons/plusSmallIcon.svg'
import fragileIcon from './icons/fragileIcon.svg'
import notTurnIcon from './icons/notTurnIcon.svg'
import stackIcon from './icons/stackIcon.svg'
import angleOverhangIcon from './icons/angleOverhangIcon.svg'
import moveIcon from './icons/moveIcon.svg'
import starIcon from './icons/starIcon.svg'
import starFillIcon from './icons/starFillIcon.svg'
import eyeIcon from './icons/eyeIcon.svg'
import backIcon from './icons/backIcon.svg'
import homeIcon from './icons/homeIcon.svg'
import pdfIcon from './icons/pdfIcon.svg'
import excelIcon from './icons/excelIcon.svg'
import okIcon2 from './icons/okIcon2.svg'
import userIcon from './icons/userIcon.svg'
import usersIcon from './icons/usersIcon.svg'
import demoIcon from './icons/demoIcon.svg'
import avatar24 from './icons/avatar24.svg'
import mailIcon from './icons/mail.svg'
import cancelIcon from './icons/cancel.svg'
import helpMessageIcon from './help.svg'
import clockWiseIcon from './clockWise.svg'
import reportFilesIcon from './reportFiles.svg'

import vkFooterLink from './icons/vkFooterLink.svg'
import isRotateY from './icons/isRotateY.svg'
// ------- Icons cargo -------
import turnOverCargoIcon from './icons/turnOverIcon.svg'
import stackCargoIcon from './icons/stackCargoIcon.svg'
import overhangCargoAngleIcon from './icons/overhangCargoAngeIcon.svg'
// ------- Icons cargo space -------
import wagonsIcon from './icons/wagonsIcon.svg'
import containersIcon from './icons/containersIcon.svg'
// import palletIcon from "./icons/palletIcon.svg"
import semitrailerIcon from './icons/semitrailerIcon.svg'

// ------- NEW REDESIGN Icons cargo space -------
import vanIcon from './icons/van_icon.svg'
import activeVan from './icons/activeVan.svg'
import truckIcon from './icons/truck_icon.svg'
import activeTruck from './icons/activeTruck.svg'
import containerIcon from './icons/container_icon.svg'
import activeContainer from './icons/activeContainer.svg'
import palletIcon from './icons/pallet_icon.svg'
import activePallet from './icons/activePallet.svg'

// ------- NEW REDESIGN FULL Icons cargo space -------

import truckFullIcon from './icons/truck_icon_full.svg'
import vanFullIcon from './icons/van_icon_full.svg'
import containerFullIcon from './icons/container_icon_full.svg'
import palletFullIcon from './icons/pallet_icon_full.svg'

// ------- Icons other -------
import emptyIcon from './icons/carbon_image.svg'
import smileIcon from './icons/smile.svg'
import sortUpIcon from './icons/sortUpIcon.svg'
import sortDownIcon from './icons/sortDownIcon.svg'
import sendIcon from './sent-icon.svg'
import noSendIcon from './nosent-icon.svg'
import phoneIcon from './phone.svg'
import emailIcon from './email.svg'
import workResultIcon from './icons/workResultIcon.svg'
import landingProfile from './icons/landingProfile.svg'
import headerMenu from './icons/headerMenu.svg'
import closeHeaderMenu from './icons/closeHeaderMenu.svg'
import page404Image1 from './image-page404.svg'
import page404Image2 from './image-page404-1.svg'
import infinity from './icons/infinity.svg'
import listIcon from './icons/list.svg'

// ------- Cargo illustrations -------
import cargo1 from './cargo/cargo1.svg'
import cargo2 from './cargo/cargo2.svg'

// ------- Empty tabs illustrations -------
import spacesEmpty from './tabs/spacesEmpty.svg'
import cargoEmpty from './tabs/cargoEmpty.svg'
import calculationEmpty from './tabs/calculationEmpty.svg'
import historyEmpty from './tabs/historyEmpty.svg'
import arrowEmpty from './tabs/arrowEmpty.svg'

// ------- Help page illustrations -------
import supportImage from './help/supportImage.svg'
import youtubeImage from './help/youtubeImage.svg'
import youtubeBg from './youtubeBg.svg'

// ------- Authorization/Registration aside illustrations -------
import asideReg from './authAside/asideReg.svg'
import AsideAuth from './authAside/AsideAuth.svg'
import regForm from './authAside/regForm.svg'
// ------- How to work with GLS -------
import how1 from './how1.svg'
import how2 from './how2.svg'
import how3 from './how3.svg'
import how4 from './how4.svg'
import how5 from './how5.svg'
import how6 from './how6.svg'
// ------- Load Performance -------
import loadPerformance from './loadPerformance.png'
// ------- Possibilities -------
import possibilities1 from './possibilities1.png'
import possibilities2 from './possibilities2.png'
import possibilities3 from './possibilities3.png'
// ------- Suitability -------
import suitability1 from './suitability1.png'
import suitability2 from './suitability2.png'
import suitability3 from './suitability3.png'
// ------- Solutions -------
import frequentProblems1 from './frequentProblems1.svg'
import frequentProblems2 from './frequentProblems2.svg'
import frequentProblems3 from './frequentProblems3.svg'
// ------- Partners -------
import partner1 from './partner1.png'
import partner2 from './partner2.png'

// ------- Footer -------
import mailTransparentIcon from './icons/mailTransparentIcon.svg'
import phoneTransparentIcon from './icons/phoneTransparentIcon.svg'
import markerTransparentIcon from './icons/markerTransparentIcon.svg'

// ------- Cargo Detail -------

import cargoDetail from './cargo_detail.svg'

// ------- ProfileHelp -------

import helpQuest from './helpQuest.png'

// ------- Modal Icon -------
import crown from './icons/crown.svg'
import dollar from './icons/dollar.svg'
import warningIcon from './icons/warning.svg'
import save from './icons/save.svg'
import happySmile from './icons/happySmile.svg'
import selectedStar from './icons/selectedStar.svg'

// ------- Textures image -------
import palletTexture from './textures/pallet-texture.jpg'
import cargoTexture from './textures/cargo-texture-v-2.jpg'

// ----------------tikkurila----------------
import tikkurilaIcon from './icons/tikkurila.svg'

// ----------------loader----------------
import boxLoader from './loaders/box-loader.svg'

// ----------------faq----------------
import openDetails from './icons/openDetails.svg'
import closeDetails from './icons/closeDetails.svg'

// ----------------promocodes----------------
import promocodes from './icons/promocodes.svg'
import activeCircle from './icons/activeCircle.svg'
import inactiveCircle from './icons/inactiveCircle.svg'
// -----------------helpPage------------------
import helpEmpty from './helpEmpty.png'

// -----------------PaymentPage------------------
import payment from './payment.png'

// -----------------aside panel in scene------------------
import receintIcon from './icons/receint.svg'
import stepLoadIcon from './icons/stepLoad.svg'
import settingIcon from './icons/Setting.svg'
import callorder from './callorder.png'

// -----------------tariff page------------------

import LightTariffCard from './LightTariffCard.png'
import macBook from './MacbookPro.png'
import workSlider from './WorkSider.png'

// -----------------company page------------------

import camera from './camera.svg'
import userProfile from './userProfile.svg'
import companyLogo from './companyPhoto.svg'
import teamwork from './teamwork.png'
import staff from './staff.png'
import disabledCompany from './disabledCompany.svg'
import emptyStaff from './emptyStaff.svg'
import commonAcount from './icons/commonAcount.svg'

// ---------------mailing page --------------------
import unsubscribeMailing from './unsubscribeMailing.png'
import unsubscribeMailing2 from './unsubscribeMailing2.png'
import unsubscribeMailing3 from './unsubscribeMailing3.png'

import pdfFileIcon from './icons/pdfFileIcon.svg'

export {
  clockWiseIcon,
  reportFilesIcon,
  vkFooterLink,
  pdfFileIcon,
  isRotateY,
  unsubscribeMailing,
  unsubscribeMailing2,
  unsubscribeMailing3,
  helpMessageIcon,
  palletAndBoxIcon,
  palletIconProject,
  cargosOutlineIcon,
  updateIcon,
  commonAcount,
  emptyStaff,
  mailIcon,
  cancelIcon,
  camera,
  avatar24,
  disabledCompany,
  companyLogo,
  workBack,
  userProfile,
  staff,
  teamwork,
  LightTariffCard,
  workSlider,
  macBook,
  callorder,
  reportIcon,
  helpEmpty,
  selectedStar,
  happySmile,
  emptyReports,
  arrowIcon,
  garpixLogo,
  activeCircle,
  inactiveCircle,
  promocodes,
  closeCircle,
  closeDetails,
  openDetails,
  emptyIcon,
  infinity,
  smileIcon,
  russianFlag,
  regForm,
  palletTexture,
  cargoTexture,
  stackCargoIcon,
  overhangCargoAngleIcon,
  turnOverCargoIcon,
  arrowEmpty,
  helpQuest,
  activePallet,
  activeContainer,
  activeTruck,
  activeVan,
  save,
  warningIcon,
  dollar,
  crown,
  cargoDetail,
  truckFullIcon,
  palletFullIcon,
  vanFullIcon,
  containerFullIcon,
  attentionBanner,
  userIcon,
  usersIcon,
  demoIcon,
  logoPro,
  newLogoPro,
  closeHeaderMenu,
  landingProfile,
  headerMenu,
  logo,
  downIcon,
  notificationsIcon,
  downloadIcon,
  profileIcon,
  helpIcon,
  exitIcon,
  searchIcon,
  fileIcon,
  plusIcon,
  leftIcon,
  rightIcon,
  sortUpIcon,
  sortDownIcon,
  moreIcon,
  editIcon,
  copyIcon,
  shareIcon,
  deleteIcon,
  wagonsIcon,
  semitrailerIcon,
  vanIcon,
  containerIcon,
  truckIcon,
  containersIcon,
  closeIcon,
  palletIcon,
  errorIcon,
  cargo1,
  cargo2,
  doneIcon,
  spacesIcon,
  cargoIcon,
  calculationIcon,
  historyIcon,
  playIcon,
  spacesEmpty,
  cargoEmpty,
  addCargoIcon,
  minusSmallIcon,
  plusSmallIcon,
  fragileIcon,
  notTurnIcon,
  stackIcon,
  angleOverhangIcon,
  moveIcon,
  repeatIcon,
  closeSmallIcon,
  doubleLeftIcon,
  doubleRightIcon,
  calculationEmpty,
  historyEmpty,
  starIcon,
  starFillIcon,
  eyeIcon,
  supportImage,
  youtubeImage,
  asideReg,
  backIcon,
  logoText,
  AsideAuth,
  sendIcon,
  noSendIcon,
  homeIcon,
  phoneIcon,
  emailIcon,
  how1,
  how2,
  how3,
  how4,
  how5,
  how6,
  loadPerformance,
  possibilities1,
  possibilities2,
  possibilities3,
  suitability1,
  suitability2,
  suitability3,
  workResultIcon,
  frequentProblems1,
  frequentProblems2,
  frequentProblems3,
  partner1,
  partner2,
  okIcon,
  failIcon,
  mailTransparentIcon,
  phoneTransparentIcon,
  markerTransparentIcon,
  pdfIcon,
  excelIcon,
  okIcon2,
  page404Image1,
  page404Image2,
  tikkurilaIcon,
  boxLoader,
  payment,
  receintIcon,
  stepLoadIcon,
  settingIcon,
  youtubeBg,
  listIcon
}
