/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useSWRConfig } from 'swr'
import { GxIcon, GxMenu, GxSpinner } from '@garpix/garpix-web-components-react'
import api from '@/api/services/project'
import Autocomplite from '@/apps/Autocomplite'
import useTranslate from '@/hooks/useTranslate'

import Input from '@/views/Input'
import SelectViews from '@/views/SelectViews'
import Button from '@/views/Button'

import { plusIcon, okIcon, errorIcon } from '@/images'

import { convertErrorToForm } from '@/utils'
import { uploadFileGazpromProjectSchema } from '@/utils/ValidateSchemes'
import { BUTTON_THEME, KEYS_FETCHER, INTL_ERROR_MESSAGE, INPUT_REGEXP } from '@/const'
import {
  units,
  weightUnits,
  vghs,
  atomicSelect,
  serializeOnlyNeesValues,
  fetchergetTemplatesExel
} from './const'
import styles from './styles/index.module.scss'

const UploadFileProjectGazpromModal = ({
  handleSamleXlsxLoadRef,
  isEdit = false,
  id,
  mutateProp,
  handleClose
}) => {
  const [isSuccesSaveTemplate, setIsSuccesSaveTemplate] = useState(false)
  const [isRejectSaveTemplate, setIsRejectSaveTemplate] = useState(false)
  const [isSavingTemplate, setIsSavingTemplate] = useState(false)
  const [isSelectedTemplate, setIsSelectedTemplate] = useState(null)
  const { translate, intl } = useTranslate()
  const { mutate } = useSWRConfig()

  let timer // таймер для простоты очистки

  const initialValues = {
    sheet_title: '',
    column_article: '',
    column_count: '',
    column_height: '',
    column_length: '',
    column_mass: '',
    column_sizes: '',
    column_title: '',
    column_width: '',
    delimiter: '',
    is_rotate_y: true,
    stacking: true,
    start_from_line: '',
    title: '',
    turnover: false,
    type_vgh: vghs[1].value,
    dimensions_unit: units[0].value,
    weight_unit: weightUnits[0].value
  }

  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    setValues,
    setFieldValue,
    handleChange: handleChangeOrigin,
    setErrors,
    setTouched,
    touched,
    isSubmitting,
    setSubmitting
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (isEdit) {
        setSubmitting(true)
        api
          .putTemplatesExel(values.id, values)
          .then(() => {
            mutateProp()
            handleClose()
          })
          .catch((err) => {
            setErrors(convertErrorToForm(err)).then(() => {
              setTouched(convertErrorToForm(err))
            })
          })
          .finally(() => {
            setSubmitting(false)
          })
      } else {
        const data = isSelectedTemplate
          ? { template_id: values.id }
          : { ...serializeOnlyNeesValues(values) }
        handleSamleXlsxLoadRef.data = data
        handleSamleXlsxLoadRef.click()
        handleClose()
      }
    },
    validationSchema: uploadFileGazpromProjectSchema
  })

  const handleChange = (e) => {
    handleChangeOrigin(e)
    setIsSelectedTemplate(false)
  }

  const createTemplate = (values = {}) => {
    if (Object.keys(errors).length === 0) {
      setIsSavingTemplate(true)
      api
        .postTemplatesExel(values)
        .then(() => {
          setIsSuccesSaveTemplate(true)
          timer = setTimeout(() => {
            setIsSuccesSaveTemplate(false)
            clearTimeout(timer)
          }, 1500)
        })
        .catch((err) => {
          const errorForm = convertErrorToForm(err)
          setTouched(errorForm).then(() => {
            setErrors(errorForm)
          })
          setIsRejectSaveTemplate(true)
          timer = setTimeout(() => {
            setIsRejectSaveTemplate(false)
            clearTimeout(timer)
          }, 1500)
        })
        .finally(() => {
          mutate('get_xlsx_template')
          setIsSavingTemplate(false)
        })
    } else {
      setTouched(errors).then(() => {
        setErrors(errors)
      })
    }
  }

  const handlePreloadInitValues = () => {
    api.getCurrentTemplatesExel(id).then((res) => {
      setValues(res)
    })
  }

  const handleChangeTemplate = (template) => {
    if (!template) {
      const fieldset = Object.keys(initialValues).reduce(
        (acc, v) => {
          acc.error[v] = INTL_ERROR_MESSAGE.REQUIRED
          acc.touched[v] = true
          return acc
        },
        { error: {}, touched: {} }
      )
      setValues(initialValues)
      setIsSelectedTemplate(false)
      setTouched(fieldset.touched).then(() => {
        setErrors(fieldset.error)
      })
    } else {
      setValues(template)
      setIsSelectedTemplate(true)
    }
  }

  const handleChangeTemplateInput = (v) => {
    setFieldValue('title', v)
    setIsSelectedTemplate(false)
  }

  const handleChangeTypeVGH = (e) => {
    const value = e.target.value
    if (value === vghs[2].value) {
      setValues({
        ...values,
        type_vgh: value,
        weight_unit: weightUnits[0].value,
        dimensions_unit: units[0].value,
        column_mass: '',
        column_article: '',
        stacking: null,
        turnover: null,
        is_rotate_y: null
      })
    } else {
      setValues({
        ...values,
        is_rotate_y: true,
        stacking: true,
        turnover: false
      })
      handleChange(e)
    }
    setIsSelectedTemplate(false)
  }

  const getStatusSaveTemplate = () => {
    if (isSavingTemplate) {
      return (
        <div className={styles.wrapper__status}>
          <GxSpinner className={styles.status_icon} />
          {intl.formatMessage({
            id: 'saving',
            defaultMessage: 'Сохраняем...'
          })}
        </div>
      )
    }
    if (isSuccesSaveTemplate) {
      return (
        <div className={styles.wrapper__status}>
          <GxIcon src={okIcon} className={styles.status_icon} />
          {intl.formatMessage({
            id: 'success_save_temlate',
            defaultMessage: 'Шаблон был успешно сохранен'
          })}
        </div>
      )
    }
    if (isRejectSaveTemplate) {
      return (
        <div className={styles.wrapper__status}>
          <GxIcon src={errorIcon} className={styles.status_icon} />
          {intl.formatMessage({
            id: 'reject_save_template',
            defaultMessage: 'Не получилось сохранить шаблон. Попробуйте еще раз'
          })}
        </div>
      )
    }
    return null
  }

  const getUseLast = () => {
    api.getUseLastTemplatesExel().then((res) => {
      setValues(res)
      setIsSelectedTemplate(res.id)
    })
  }

  useEffect(() => {
    if (isEdit) {
      handlePreloadInitValues()
    } else {
      getUseLast()
    }
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const TYPES_VGH_FILDS = {
    [vghs[0].value]: (
      <>
        <div className={styles.form__field_group_small}>
          <Input
            label={intl.formatMessage({
              id: 'delimiter',
              defaultMessage: 'Разделитель'
            })}
            name='delimiter'
            onGx-input={handleChange}
            onGx-blur={handleBlur}
            value={values.delimiter}
            error={touched.delimiter && errors.delimiter}
            errorText={errors.delimiter}
            required
          />
        </div>
        <div className={styles.form__field_group_small}>
          <Input
            label={intl.formatMessage({
              id: 'column_sizes',
              defaultMessage: 'Столбец'
            })}
            name='column_sizes'
            onGx-input={handleChange}
            regexp={INPUT_REGEXP.NON_LATIN_INT}
            onGx-blur={handleBlur}
            value={values.column_sizes}
            error={touched.column_sizes && errors.column_sizes}
            errorText={errors.column_sizes}
            required
          />
        </div>
      </>
    ),
    [vghs[1].value]: (
      <>
        <div className={styles.form__field_group}>
          <Input
            label={intl.formatMessage({
              id: 'column_length',
              defaultMessage: 'Столбец длины'
            })}
            name='column_length'
            onGx-input={handleChange}
            onGx-blur={handleBlur}
            regexp={INPUT_REGEXP.NON_LATIN_INT}
            value={values.column_length}
            error={touched.column_length && errors.column_length}
            errorText={errors.column_length}
            required
          />
        </div>
        <div className={styles.form__field_group}>
          <Input
            label={intl.formatMessage({
              id: 'column_width',
              defaultMessage: 'Столбец ширины'
            })}
            name='column_width'
            onGx-input={handleChange}
            onGx-blur={handleBlur}
            regexp={INPUT_REGEXP.NON_LATIN_INT}
            value={values.column_width}
            error={touched.column_width && errors.column_width}
            errorText={errors.column_width}
            required
          />
        </div>
        <div className={styles.form__field_group}>
          <Input
            label={intl.formatMessage({
              id: 'column_height',
              defaultMessage: 'Столбец высоты'
            })}
            name='column_height'
            onGx-input={handleChange}
            onGx-blur={handleBlur}
            regexp={INPUT_REGEXP.NON_LATIN_INT}
            value={values.column_height}
            error={touched.column_height && errors.column_height}
            errorText={errors.column_height}
            required
          />
        </div>
      </>
    ),
    [vghs[2].value]: null
  }

  return (
    <div className={styles.form__layout}>
      <h1 className={styles.form__title}>
        {isEdit
          ? intl.formatMessage({
              id: 'change_template',
              defaultMessage: 'Изменение шаблона'
            })
          : intl.formatMessage({
            id: 'params_template',
            defaultMessage: 'Параметры'
          })}
      </h1>
      {isEdit
        ? (
          <Input
            label={intl.formatMessage({
              id: 'title',
              defaultMessage: 'Название шаблона'
            })}
            name='title'
            onGx-input={handleChange}
            onGx-blur={handleBlur}
            value={values.title}
            error={touched.title && errors.title}
            errorText={translate(errors.title)}
            required
          />
          )
        : (
          <Autocomplite
            label={intl.formatMessage({
              id: 'template',
              defaultMessage: 'Шаблон'
            })}
            placeholder={intl.formatMessage({
              id: 'selected_or_create',
              defaultMessage: 'Выберите или создайте'
            })}
            name='title'
            inputValue={values.title}
            error={errors.title}
            errorText={translate(errors.title)}
            keyFetcher={KEYS_FETCHER.get_xlsx_template}
            fetchUrl={fetchergetTemplatesExel}
            handleChangeInputProxy={handleChangeTemplateInput}
            handleChange={handleChangeTemplate}
          />
          )}

      <div className={styles.form__block}>
        <h1 className={styles.form__sub_title}>Основные</h1>
        <div className={styles.form__group_field}>
          <div className={styles.form__field_group_small}>
            <Input
              label={intl.formatMessage({
                id: 'sheet_title',
                defaultMessage: 'Название листа'
              })}
              name='sheet_title'
              onGx-input={handleChange}
              onGx-blur={handleBlur}
              value={values.sheet_title}
              error={touched.sheet_title && errors.sheet_title}
              errorText={translate(errors.sheet_title)}
              required
            />
          </div>

          <div className={styles.form__field_group_small}>
            <Input
              label={intl.formatMessage({
                id: 'start_from_line',
                defaultMessage: 'Начинать со строки'
              })}
              name='start_from_line'
              onGx-input={handleChange}
              onGx-blur={handleBlur}
              regexp={INPUT_REGEXP.NON_INT}
              value={values.start_from_line}
              error={touched.start_from_line && errors.start_from_line}
              errorText={translate(errors.start_from_line)}
              required
            />
          </div>
        </div>
        <div className={styles.form__group_field}>
          <div className={styles.form__field_group}>
            <Input
              label={intl.formatMessage({
                id: 'column_title',
                defaultMessage: 'Столбец названия'
              })}
              name='column_title'
              onGx-input={handleChange}
              regexp={INPUT_REGEXP.NON_LATIN_INT}
              onGx-blur={handleBlur}
              value={values.column_title}
              error={touched.column_title && errors.column_title}
              errorText={errors.column_title}
              required
            />
          </div>
          <div className={styles.form__field_group}>
            <Input
              label={intl.formatMessage({
                id: 'column_article',
                defaultMessage: 'Столбец артикулов'
              })}
              name='column_article'
              onGx-input={handleChange}
              onGx-blur={handleBlur}
              regexp={INPUT_REGEXP.NON_LATIN_INT}
              value={values.column_article}
              error={touched.column_article && errors.column_article}
              errorText={errors.column_article}
              disabled={values.type_vgh === vghs[2].value}
            />
          </div>
          <div className={styles.form__field_group}>
            <Input
              label={intl.formatMessage({
                id: 'column_count',
                defaultMessage: 'Столбец количества'
              })}
              name='column_count'
              onGx-input={handleChange}
              onGx-blur={handleBlur}
              regexp={INPUT_REGEXP.NON_LATIN_INT}
              value={values.column_count}
              error={touched.column_count && errors.column_count}
              errorText={errors.column_count}
            />
          </div>
        </div>
      </div>
      <div className={styles.form__block}>
        <h1 className={styles.form__sub_title}>ВГХ</h1>
        <div className={styles.form__group_field}>
          <div className={styles.form__field_group_small}>
            <Input
              label={intl.formatMessage({
                id: 'column_mass',
                defaultMessage: 'Столбец массы'
              })}
              name='column_mass'
              onGx-input={handleChange}
              onGx-blur={handleBlur}
              regexp={INPUT_REGEXP.NON_LATIN_INT}
              value={values.column_mass ? values.column_mass : ' '}
              error={touched.column_mass && errors.column_mass}
              errorText={translate(errors.column_mass)}
              required={values.type_vgh !== vghs[2].value}
              disabled={values.type_vgh === vghs[2].value}
            />
          </div>
          <div className={styles.form__field_group_small}>
            <SelectViews.Select
              name='weight_unit'
              value={values.weight_unit}
              label={intl.formatMessage({
                id: 'weight_unit',
                defaultMessage: 'ЕИ Массы'
              })}
              onGx-change={handleChange}
              required
              disabled={values.type_vgh === vghs[2].value}
            >
              <GxMenu className={styles.gx__menu}>
                {weightUnits.map(({ id, label, value }) => (
                  <SelectViews.Item
                    key={id}
                    value={value}
                    selected={values.weight_unit === value}
                  >
                    {intl.formatMessage(label)}
                  </SelectViews.Item>
                ))}
              </GxMenu>
            </SelectViews.Select>
          </div>
        </div>
        <div className={styles.form__group_field}>
          <div className={styles.form__field_group_small}>
            <SelectViews.Select
              name='dimensions_unit'
              value={values.dimensions_unit}
              label={intl.formatMessage({
                id: 'dimensions_unit',
                defaultMessage: 'ЕИ Размеров'
              })}
              onGx-change={handleChange}
              required
              disabled={values.type_vgh === vghs[2].value}
            >
              {units.map(({ id, label, value }) => (
                <SelectViews.Item
                  key={id}
                  value={value}
                  selected={values.dimensions_unit === value}
                >
                  {intl.formatMessage(label)}
                </SelectViews.Item>
              ))}
            </SelectViews.Select>
          </div>
          <div className={styles.form__field_group_small}>
            <SelectViews.Select
              name='type_vgh'
              value={values.type_vgh}
              label={intl.formatMessage({
                id: 'type_vgh',
                defaultMessage: 'Размеры'
              })}
              onGx-change={handleChangeTypeVGH}
              required
            >
              {vghs.map(({ id, label, value }) => (
                <SelectViews.Item
                  key={id}
                  value={value}
                  selected={values.type_vgh === value}
                >
                  {intl.formatMessage(label)}
                </SelectViews.Item>
              ))}
            </SelectViews.Select>
          </div>
        </div>
        <div className={styles.form__group_field}>
          {TYPES_VGH_FILDS[values.type_vgh]}
        </div>
      </div>
      <div className={styles.form__block}>
        <h1 className={styles.form__sub_title}>Обязательные по умолчанию</h1>
        <div className={styles.form__group_field}>
          <div className={styles.form__field_group_small}>
            <SelectViews.Select
              name='stacking'
              value={values.stacking}
              label={intl.formatMessage({
                id: 'stacking',
                defaultMessage: 'Штабелирование'
              })}
              onGx-change={handleChange}
              required
              disabled={values.type_vgh === vghs[2].value}
            >
              {atomicSelect.map(({ id, label, value }) => (
                <SelectViews.Item
                  key={id}
                  value={value}
                  selected={values.stacking === value}
                >
                  {intl.formatMessage(label)}
                </SelectViews.Item>
              ))}
            </SelectViews.Select>
          </div>
          <div className={styles.form__field_group_small}>
            <SelectViews.Select
              name='turnover'
              value={values.turnover}
              label={intl.formatMessage({
                id: 'canting',
                defaultMessage: 'Кантование'
              })}
              onGx-change={handleChange}
              required
              disabled={values.type_vgh === vghs[2].value}
            >
              {atomicSelect.map(({ id, label, value }) => (
                <SelectViews.Item
                  key={id}
                  value={value}
                  selected={values.turnover === value}
                >
                  {intl.formatMessage(label)}
                </SelectViews.Item>
              ))}
            </SelectViews.Select>
          </div>
          <div className={styles.form__field_group_small}>
            <SelectViews.Select
              name='is_rotate_y'
              value={values.is_rotate_y}
              label={intl.formatMessage({
                id: 'rotate_vert_axsis',
                defaultMessage: 'Поворот вокруг верт. оси'
              })}
              onGx-change={handleChange}
              required
              disabled={values.type_vgh === vghs[2].value}
            >
              {atomicSelect.map(({ id, label, value }) => (
                <SelectViews.Item
                  key={id}
                  value={value}
                  selected={values.is_rotate_y === value}
                >
                  {intl.formatMessage(label)}
                </SelectViews.Item>
              ))}
            </SelectViews.Select>
          </div>
        </div>
      </div>
      <div className={styles.form__block}>
        <div className={styles.form__group_field}>{getStatusSaveTemplate()}</div>

        {isEdit
          ? (
            <div className={styles.form__group_field}>
              <div style={{ width: '100%' }} />
              <Button
                type='submit'
                onClick={handleSubmit}
                theme={BUTTON_THEME.FILL_ICON.FULL}
                disabled={isSubmitting}
              >
                {intl.formatMessage({
                  id: 'save_pattern',
                  defaultMessage: 'Сохранить шаблон'
                })}
              </Button>
            </div>
            )
          : (
            <div className={styles.form__group_field}>
              <Button
                theme={BUTTON_THEME.TRANSPARENT_BORDER.BLUE}
                disabled={isSavingTemplate || isSelectedTemplate}
                onClick={() => createTemplate(values)}
              >
                <GxIcon slot='icon-left' src={plusIcon} className={styles.plus_icon} />
                {intl.formatMessage({
                  id: 'save_pattern',
                  defaultMessage: 'Сохранить шаблон'
                })}
              </Button>

              <Button
                type='submit'
                onClick={handleSubmit}
                theme={BUTTON_THEME.FILL_ICON.FULL}
                disabled={isSubmitting}
              >
                {intl.formatMessage({
                  id: 'createProject',
                  defaultMessage: 'Создать проект'
                })}
              </Button>
            </div>
            )}
      </div>
    </div>
  )
}

export default UploadFileProjectGazpromModal
