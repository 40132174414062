import api from '@/api/services/project'
/* eslint-disable camelcase */

export const fetchergetTemplatesExel = (args = {}) => {
  const { page, page_size, search } = args
  return api.getTemplatesExel({
    page,
    page_size,
    search
  })
}
