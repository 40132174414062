import React from 'react'
import ReactDOM from 'react-dom'
import { GxModal } from '@garpix/garpix-web-components-react'
import CloseButtonView from '../../CloseButtonView'
import style from './styles/index.module.scss'

export const MODAL_THEMES = {
  default: 'default',
  large: 'large',
  large_700: 'large_700',
  DEFAULT_zINDEX_HIGHT: 'default_zIndex_hight'
}

const BaseModal = ({
  handleClose,
  children,
  theme = 'default',
  dataAttr,
  modalProps,
  colorTheme = null,
  ...props
}) => {
  return ReactDOM.createPortal(
    <GxModal
      {...props}
      data-modal={dataAttr}
      className={style[theme]}
      style={colorTheme}
    >
      <CloseButtonView
        closeModal={handleClose}
        className={style[`${theme}__close`]}
      />
      {children}
    </GxModal>,
    document.body
  )
}

export default BaseModal
