import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { useIntl } from 'react-intl'
import Button from '../../Button'
// import { useModalStore } from '@/hooks/Modals'
import { useSidebar } from '@/hooks/useSidebar'

import { listIcon } from '@/images'
import { BUTTON_THEME, SIDEBAR_TYPES } from '@/const'
import styles from './styles/index.module.scss'

const OpenTemplateList = () => {
  const intl = useIntl()
  const { handleOpen } = useSidebar()
  //   const { openModal } = useModalStore()
  //   const warningError = useModalIntlContent(contentTypes.warning)

  const handleOpenSidebar = async () => {
    handleOpen({ type: SIDEBAR_TYPES.TEMPLATES_PROJECTS })
  }

  return (
    <Button onClick={handleOpenSidebar} theme={BUTTON_THEME.STROKE_ICON.SMALL}>
      <GxIcon src={listIcon} slot='icon-left' className={styles.icon} />
      {intl.formatMessage({
        id: 'templates',
        defaultMessage: 'Шаблоны'
      })}
    </Button>
  )
}

export default OpenTemplateList
