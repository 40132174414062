import React from 'react'
import { GxSpinner } from '@garpix/garpix-web-components-react'
import style from './styles/index.module.scss'
import classNames from 'classnames'

const Spinner = ({ variant = 'default', className, slot }) => {
  return (
    <div
      className={classNames(style.spinner_wrap, className)}
      slot={slot}
    >
      <GxSpinner className={style[`spinner_${variant}`]} />
    </div>
  )
}

export default Spinner
