import React from 'react'
import { NoSSR } from '../../components/NoSSR'
import BaseModal from './Base'
import useModalStore from '../../hooks/Modals/useModalStore'
import {
  PromocodeInfo,
  Confirm,
  CommonConfirm,
  SaveCargo,
  SaveCargoSpace,
  Remove,
  HomeVideo,
  ExpirationTariff,
  UserInCompany,
  UploadFile,
  ShowCargoSpaceView,
  UploadExel
} from './ModalContent'

import {
  MoveCargoModal,
  ChangeUserAvatarModal,
  CreateProjectModal,
  TariffLimitModal,
  RemovePageItemModal,
  RenamePageItemModal,
  CopyPageItemModal,
  ChangePasswordModal,
  HeaderMenuModal,
  SendRequestModal,
  RenameModal,
  PromoCashModal,
  QuitAccountModal,
  GradeModal,
  ChangeTariffModal,
  DeleteStaffModal,
  AddStaffModal,
  ChangeCompanyOwnerModal,
  UploadFileProjectGazpromModal
} from '../../components/Modals'

/**
 * набор модалок
 * key- тип модалки
 * value - компонента контеента модалки
 */
const modals = {
  userInCompany: UserInCompany,
  changeCompanyOwner: ChangeCompanyOwnerModal,
  changeTariff: ChangeTariffModal,
  deleteStaff: DeleteStaffModal,
  addStaff: AddStaffModal,
  rename: RenameModal,
  homeVideo: HomeVideo,
  changePassword: ChangePasswordModal,
  headerMenu: HeaderMenuModal,
  remove: Remove,
  sendRequest: SendRequestModal,
  commonConfirm: CommonConfirm,
  saveCargoSpace: SaveCargoSpace,
  saveCargo: SaveCargo,
  renamePageItem: RenamePageItemModal,
  removePageItem: RemovePageItemModal,
  copyPageItem: CopyPageItemModal,
  tariffLimit: TariffLimitModal,
  createProject: CreateProjectModal,
  moveCargo: MoveCargoModal,
  confirm: Confirm,
  promoCash: PromoCashModal,
  promocodeInfo: PromocodeInfo,
  quitAccount: QuitAccountModal,
  grade: GradeModal,
  expirationTariff: ExpirationTariff,
  changeUserAvatar: ChangeUserAvatarModal,
  uploadFile: UploadFile,
  showCargoSpaceView: ShowCargoSpaceView,
  uploadExel: UploadExel,
  uploadFileProjectGazprom: UploadFileProjectGazpromModal,
  empty: () => null
}

const Modals = () => {
  const { closeModal, showModal, isOpen, modalProps, setUnclosable, isUnclosable } =
    useModalStore()

  const CurrentModal = modals[showModal ?? 'empty']
  const afterHideModal = () => closeModal()

  const onOverlayDismiss = (e) => {
    if (isUnclosable) {
      e.preventDefault()
    }
  }

  const handleCloseBaseModal = !isUnclosable ? closeModal : undefined

  return (
    <NoSSR>
      <BaseModal
        handleClose={handleCloseBaseModal}
        open={isOpen || undefined}
        onGx-after-hide={afterHideModal}
        onGx-overlay-dismiss={onOverlayDismiss}
        dataAttr={showModal}
        theme={modalProps.theme}
        colorTheme={modalProps?.colorTheme}
      >
        <CurrentModal
          {...modalProps}
          setUnclosable={setUnclosable}
          isUnclosable={isUnclosable}
          handleClose={closeModal}
        />
      </BaseModal>
    </NoSSR>
  )
}

export default Modals
