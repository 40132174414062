import React, { useState } from 'react'

import classNames from 'classnames'
import { runtimeConfig } from '../../config'
import { APP_ENVIRONMENT, BUTTON_THEME, BUTTON_VARIANT, ENTITY_TYPES } from '../../const'
import { downIcon, exitIcon, logo } from '../../images'

import { Link } from 'react-router-dom'
import NotificationsMenuComponent from '../../apps/appNotifications/components/NotificationsMenuComponent'
import LocaleMenu from '../../apps/components/views/LocaleMenu'
import Button from '../Button'
import { GxIcon } from '@garpix/garpix-web-components-react'
import DropdownViews from '../DropdownViews'
import UserIcon from '../UserIcon'

import style from './style/index.module.scss'
import usePage from '../../hooks/usePage'

/**
 * Шапка App
 *
 * @param {Array<Object>} mainMenuLinks Список ссылок списочных страниц
 * @param {Array<Object>} profileMenuLinks Список ссылок меню (навбар/табы)
 * @param {string} activePage Активная страница
 * @param {string} logoLink Ссылка для Лого
 * @param {boolean} showOnMobile Флаг на отображение навбара
 * @param {function(): void} logout Метод для логаута
 * @return {JSX.Element}
 */
const Header = ({
  mainMenuLinks,
  profileMenuLinks,
  activePage,
  logout,
  logoLink,
  showOnMobile = true
}) => {
  const isProjectPage = ENTITY_TYPES.PROJECT.toLowerCase() === activePage.toLowerCase()

  const { page } = usePage()
  const { global: { image_logo: imageLogo } } = page.init_state

  /**
   * Состояние открытия меню
   */
  const [open, setOpen] = useState(null)

  const handleCloseMenu = () => setOpen(null)

  /**
   * Метод для логаута + закрытие меню
   */
  const handleLogout = () => {
    logout()
    handleCloseMenu()
  }

  return (
    <header className={style.header}>
      <Link to={logoLink} className={style.header__logo_wrap}>
        <img
          className={style.header__logo_image}
          src={imageLogo || logo}
          width='40px'
          height='40px'
          alt='Logo'
        />
      </Link>
      <nav
        data-page={activePage} className={classNames({
          [style.header_nav]: true,
          [style['header_nav--project']]: isProjectPage,
          [style['header_nav--hidden']]: !showOnMobile
        })}
      >
        <div className={style['header_nav-wrap']}>
          {mainMenuLinks &&
            mainMenuLinks.map((link) => {
              return (
                <Link
                  data-cy={`${link.model_name}-tab-link`}
                  key={link.id}
                  to={link.url}
                  className={classNames({
                    [style.header_nav__link]: true,
                    [style['header_nav__link--active']]:
                      activePage === link?.model_name
                  })}
                >
                  <GxIcon src={link.icon} className={style.header_nav__link_icon} />
                  {link.title}
                </Link>
              )
            })}
        </div>
      </nav>
      <div className={style.header_right}>
        {
          runtimeConfig.RAZZLE_APP_ENVIRONMENT !== APP_ENVIRONMENT.PRODUCTION && (
            <div className={style.header_language}>
              <LocaleMenu />
            </div>
          )
        }
        <NotificationsMenuComponent />
        <div className={style.header_profile}>
          <DropdownViews.Dropdown
            open={open}
            onGx-show={() => setOpen(true)}
            onGx-hide={handleCloseMenu}
            hoist
            placement='bottom-end'
            className={style.header__details}
          >
            <Button
              slot='trigger'
              caret
              variant={BUTTON_VARIANT.TEXT}
              theme={BUTTON_THEME.PROFILE_DROPDOWN}
            >
              <UserIcon />
              <GxIcon
                slot='icon-right'
                src={downIcon}
                className={style.header_language__icon}
              />
            </Button>
            <DropdownViews.Menu projectVariant='header_profile'>
              {profileMenuLinks &&
                profileMenuLinks.map((item) => {
                  return (
                    <DropdownViews.Item
                      onClick={handleCloseMenu}
                      className={style.header_profile__item}
                      key={item.id}
                    >
                      <Link
                        data-cy={`${item.model_name}-menu-link`}
                        to={item.url}
                        className={style.header_profile__link}
                      >
                        <GxIcon
                          src={item.icon}
                          className={style.header_profile__icon}
                          slot='prefix'
                        />
                        {item.title}
                      </Link>
                    </DropdownViews.Item>
                  )
                })}
              <DropdownViews.Divider />
              <DropdownViews.Item
                onClick={handleLogout}
                className={style.header_profile__logout}
              >
                <GxIcon
                  src={exitIcon}
                  className={classNames(
                    style.header_profile__icon,
                    style['header_profile__icon--logout']
                  )}
                  slot='prefix'
                />
                Выйти
              </DropdownViews.Item>
            </DropdownViews.Menu>
          </DropdownViews.Dropdown>
        </div>
      </div>
    </header>
  )
}
export default React.memo(Header)
