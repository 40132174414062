import React, { useMemo } from 'react'
import classNames from 'classnames'
import { GxButton, GxIcon } from '@garpix/garpix-web-components-react'
import useTranslate from '../../../../hooks/useTranslate'
import { useMedia } from 'react-use'

import AdvantageIconsList from '../TariffAdvantageIconsView'
import TariffTextAdvantages from '../TariffTextAdvantages'
import UserTariffCircle from './UserTariffCircle'
import TariffLabel from '../TariffLabelView'
import Tooltip from '../../../../views/Tooltip'
import Button from '../../../../views/Button'

import { crown, doneIcon, LightTariffCard } from '../../../../images'
import { reduceAdvantages, sortAdvantages } from '../../helpers'
import { formatPrice, formattingDate } from '../../../../utils'
import { PROMOTION_TYPE } from '../../helpers/const'

import styles from './styles/index.module.scss'

const TariffCard = (props) => {
  const {
    tariff,
    active,
    isUserTariff,
    showButton = true,
    isProfile,
    amount = null,
    timeUnit
  } = props
  const { intl, formatMonth, formatInfinity } = useTranslate()
  const isTablet = useMedia('(max-width: 1024px)')
  const {
    base_type: baseType,
    promocode_info: promocodeInfo,
    promotion_description: promotionDescription,
    promotion_price: promotionPrice,
    promotion_type: promotionType,
    price_per_period: pricePerPeriod
  } = tariff
  const hasPromotionType =
    promotionType?.toLowerCase() === PROMOTION_TYPE.PRICE_PROMOTION
  const { promocode_finish_at: promocodeFinishAt } = promocodeInfo || {}
  const handleClick = () => {
    props?.handlePickCard && props.handlePickCard(tariff)
  }
  const handleBuyTariff = () => {
    props?.handleBuyTariff && props.handleBuyTariff(tariff)
  }
  const advantages = useMemo(
    () => reduceAdvantages(tariff.advantages),
    [tariff.advantages]
  )
  const sortingAdvantages = useMemo(() => sortAdvantages(advantages), [advantages])

  const showCrossedMarketingPrice =
    !hasPromotionType && tariff.marketing_price > 0
  const hasMarketingLabels = tariff?.label && tariff?.label.length > 0
  const marketingMeta = formatMonth(amount, timeUnit)

  return (
    <GxButton
      onClick={handleClick}
      className={classNames(styles.tariff_card, {
        [styles['tariff_card--profile']]: isProfile,
        [styles['tariff_card--active']]: active
      })}
    >
      {isUserTariff && <UserTariffCircle />}
      <div className={styles.tariff_card__img}>
        {promocodeInfo && (
          <div className={styles.tariff_card__promo_wrapper}>
            <div className={styles.tariff_card__promo_label}>
              <GxIcon src={doneIcon} slot='icon-left' />
              {intl.formatMessage({
                id: 'promoCode',
                defaultMessage: 'Промокод'
              })}
            </div>
            {promocodeFinishAt && (
              <div
                className={classNames(
                  styles.tariff_card__promo_label,
                  styles.tariff_card__promo_date
                )}
              >
                {formattingDate(promocodeFinishAt)}
              </div>
            )}
          </div>
        )}
        <img
          src={baseType?.image || LightTariffCard}
          alt={`${baseType?.title} image`}
          width='400'
          height='140'
        />
      </div>
      <div className={styles.tariff_card__container}>
        <div
          className={classNames(styles.tariff_card__top, {
            [styles['tariff_card__top--profile']]: isProfile
          })}
        >
          <div className={styles.tariff_card__title_container}>
            <span className={styles.tariff_card__title}>
              <GxIcon src={baseType?.icon || crown} className={styles.icon} />
              <span>{baseType?.title}</span>
            </span>
            <AdvantageIconsList advantages={sortingAdvantages.icon} />
          </div>
          <div className={styles.marketing}>
            {!hasPromotionType && (
              <div className={styles.marketing__meta}>{marketingMeta}</div>
            )}
            <div className={styles.marketing__price_wrapper}>
              {showCrossedMarketingPrice && (
                <span className={styles.marketing__price_marketing}>
                  {formatPrice(tariff.marketing_price)}
                </span>
              )}
              <span className={styles.marketing__price}>
                {hasPromotionType
                  ? formatPrice(promotionPrice)
                  : formatPrice(pricePerPeriod)}
              </span>
            </div>
            {hasMarketingLabels && (
              <ul className={styles.marketing__labels}>
                {hasPromotionType && (
                  <li>
                    <TariffLabel>Акция*</TariffLabel>
                  </li>
                )}
                {tariff.label.map((el) => {
                  return (
                    <li key={el.id}>
                      <TariffLabel>{el.title}</TariffLabel>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>
        <div className={styles.tariff_card__bottom}>
          <div className={styles.quantitative_advantages}>
            <ul className={styles.quantitative_advantages__list}>
              {sortingAdvantages.value.map((el) => {
                return (
                  el.icon &&
                  el.title && (
                    <li className={styles.quantitative_advantages__item} key={el.id}>
                      <Tooltip
                        placement='bottom'
                        projectVariant='tooltip_tabs'
                        content={el.title}
                      >
                        <GxIcon src={el.icon} className={classNames(styles.icon, styles.icon__advantages)} />
                      </Tooltip>
                      <span className={styles.quantitative_advantages__text}>
                        {formatInfinity(el.value)}
                      </span>
                    </li>
                  )
                )
              })}
            </ul>
          </div>
          <TariffTextAdvantages advantages={sortingAdvantages.text} />
        </div>
        {promotionDescription
          ? (
            <div className={styles.promotion__description}>
              *{promotionDescription}
            </div>
            )
          : null}
        {!isTablet && (
          <div className={classNames({ [styles.tariff_card__wrapper_hidden]: !(showButton && active) })}>
            <Button className={styles.tariff_card__btn} onClick={handleBuyTariff}>
              {intl.formatMessage({
                id: 'buy',
                defaultMessage: 'Купить'
              })}
            </Button>
          </div>
        )}
      </div>
    </GxButton>
  )
}

export default TariffCard
