import React from 'react'
import { useMutation } from 'react-query'

import { useModalStore, useModalIntlContent } from '@/hooks/Modals'

import { projectApiService } from '@/api/services'
import { MODAL_TYPES } from '@/const'
import { stopProp, convertError, downloadFile } from '@/utils'

import DownloadReportView from '../views/DownloadReportView'
/**
 * @component
 * @param {number} projectId - id проекта для скачивания
 */
const DownloadReport = ({ projectId }) => {
  const { openModal, contentTypes } = useModalStore()
  const content = useModalIntlContent(contentTypes.warning)

  const onError = async (err) => {
    const errorMessage = convertError(err)
    openModal(MODAL_TYPES.COMMON_CONFIRM, { ...content, description: errorMessage })
  }

  const { mutateAsync: handleDownloadLastReport, isLoading: isLastReportLoading } =
    useMutation({
      mutationFn: async () => {
        return projectApiService.downloadLastReport(projectId)
      },
      onSuccess: ({ link }) => {
        downloadFile(link)
      },
      onError
    })

  const { mutateAsync: handleDownloadAllReports, isLoading: isAllReportLoading } =
    useMutation({
      mutationFn: async () => {
        return projectApiService.downloadAllReports(projectId)
      },
      onSuccess: ({ link }) => {
        downloadFile(link)
      },
      onError
    })

  const downloadAllReportsProps = {
    handleClick: (e) => {
      stopProp(e)
      handleDownloadAllReports()
    },
    isLoading: isAllReportLoading
  }

  const downloadLastReportProps = {
    handleClick: (e) => {
      stopProp(e)
      handleDownloadLastReport()
    },
    isLoading: isLastReportLoading
  }

  return (
    <DownloadReportView
      downloadAllReportsProps={downloadAllReportsProps}
      downloadLastReportProps={downloadLastReportProps}
    />
  )
}

export default DownloadReport
