import React from 'react'
import style from './styles/index.module.scss'
import classNames from 'classnames'

const Section = ({ variant, children, className, addClass = '' }) => {
  return (
    <section className={classNames(style[variant], className)}>
      {children}
    </section>
  )
}

export default Section
